import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ScrollView, StyleSheet, TextInput, Image } from "react-native";

import { Pressable, Text, Dimensions, View, Platform } from "react-native";
import * as ImagePicker from "expo-image-picker";
import FormData from "form-data";
import { useWalletContext } from "../../providers/WalletProvider";
import { useNetwork } from "../../providers/NetworkProvider";
import { useSnackbar } from "../../providers/SnackBarProvider";
import { useLoadingContext } from "../../providers/LoadingProvider";
import { Path, Svg } from "react-native-svg";
import { Switch } from "react-native-switch";
import { useTranslation } from "react-i18next";
import { fileTypeToExtension } from "../../utils/imageController";
import React from "react";
import { useBlockchainContext } from "../../providers/BlockchainProvider";
import { CreatorType } from "../../types/creator/Creator";

export default function BecomeACreator({ navigation }: { navigation: any }) {
  const [imageBack, setImageBack] = useState<ImagePicker.ImagePickerResult>(
    {} as ImagePicker.ImagePickerResult
  );
  const [imageFront, setImageFront] = useState<ImagePicker.ImagePickerResult>(
    {} as ImagePicker.ImagePickerResult
  );
  const [username, setUsername] = useState<string>("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [isReadyToSubmit, setIsReadyTosubmit] = useState<boolean>(false);
  const { makePost } = useNetwork();
  const { walletInfo } = useWalletContext();
  const { modifyProfile } = useBlockchainContext();
  const { openSnackbar } = useSnackbar();
  const { setIsLoading } = useLoadingContext();
  const { t } = useTranslation();

  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);
  useEffect(() => {
    const hasFilleAllInputs = hasFilledAllInputs();
    setIsReadyTosubmit(hasFilleAllInputs);
  }, [username, imageBack.assets, imageFront.assets]);

  function hasFilledAllInputs(): boolean {
    let hasFilledAllInputs = false;
    if (
      username.length > 0 &&
      imageBack.assets != null &&
      imageFront.assets != null
    ) {
      hasFilledAllInputs = true;
    }
    return hasFilledAllInputs;
  }
  async function handleSubmit() {
    try {
      if (username.length === 0) {
        throw new Error("no username");
      }
      let frontUri: string = "";
      let backUri: string = "";
      let formData = new FormData();
      if (Platform.OS === "web") {
        if (imageBack.assets != null) {
          const base64 = imageBack.assets[0].uri;
          const res = await fetch(base64);
          const blob = await res.blob();
          const file = new File(
            [blob],
            "backImage" + fileTypeToExtension(blob.type),
            { type: blob.type }
          );
          formData.append("fileToUpload", file);
        } else {
          throw new Error("no back photo");
        }
      }
      if (Platform.OS === "web") {
        if (imageFront.assets != null) {
          const base64 = imageFront.assets[0].uri;
          const res = await fetch(base64);
          const blob = await res.blob();
          const file = new File(
            [blob],
            "frontImage" + fileTypeToExtension(blob.type),
            { type: blob.type }
          );
          formData.append("fileToUpload2", file);
        } else {
          throw new Error("no front photo");
        }
      }

      setIsLoading(true);
      let backType = "";
      let frontType = "";

      if (Platform.OS !== "web") {
        if (imageFront.assets != null) {
          frontUri = imageFront.assets[0].uri;
        }
        if (imageBack.assets != null) {
          backUri = imageBack.assets[0].uri;
        }
        if (backUri.length === 0) {
          throw new Error("no back photo");
        }
        if (frontUri.length === 0) {
          throw new Error("no front photo");
        }
        backType = frontUri.split(".").slice(-1)[0];
        frontType = backUri.split(".").slice(-1)[0];
        formData.append("fileToUpload", {
          uri: frontUri,
          name: "front." + frontType,
          type: "image/" + frontType,
        });
        formData.append("fileToUpload2", {
          uri: backUri,
          name: "back." + backType,
          type: "image/" + backType,
        });
      }
      formData.append("username", username);
      formData.append("mobile", true);
      formData.append("user", walletInfo?.address);
      const response = await makePost("/upload3.php", formData, {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      } as any);

      const data_ = response.data.toString();
      const data = data_.split(";");
      let errid = 0;
      if (data.length === 1) {
        errid = Number(data[0]);
      } else {
        errid = Number(data[data.length - 1]);
      }
      if (errid !== 0) {
        let errMessage = "";
        switch (errid) {
          case 1:
            errMessage = t("errors.front_photo_exeeds_20MB");
            break;
          case 2:
            errMessage = t("errors.back_photo_exeeds_20MB");
            break;
          case 3:
            errMessage = t("errors.front_photo_format_not_supported");
            break;
          case 4:
            errMessage = t("errors.back_photo_format_not_supported");
            break;
          case 5:
            errMessage = t("errors.something_went_wrong_during_file_saving");
            break;
          case 6:
            errMessage = t("errors.no_file_submitted");
            break;
          case 7:
            errMessage = t("errors.already_submitted_id_card");
            break;
          case 8:
            errMessage = t("errors.please_try_again");
            break;
          default:
            errMessage = t("errors.weird_contact_us");
        }
        openSnackbar("Errore", errMessage, "error");
      } else {
        try {
          await handleAddCreator(username);

          openSnackbar(
            "Successo",
            "Richiesta inviata correttamente",
            "success"
          );
        } catch (err) {
          openSnackbar(
            "Errore",
            t("errors.updating_information_in_smart_contract"),
            "error"
          );
        }
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      const error = err as Error;
      let message = "Something went wrong";
      if (error.message === "no username") message = t("errors.no_username");
      if (error.message === "no back photo")
        message = t("errors.no_back_document");
      if (error.message === "no front photo")
        message = t("errors.no_front_document");
      setIsLoading(false);

      openSnackbar("Errore", message, "error");
    }
  }

  async function handleAddCreator(username: string) {
    if (walletInfo == null) throw new Error("Wallet not connected");

    try {
      const creator: CreatorType = {
        address: walletInfo.address,
        name: username,
        bio: "",
        profileImageURL: "",
        coverImageURL: "",
        twitter: "",
        telegram: "",
        instagram: "",
        social4: "",
      };

      await modifyProfile(creator);
    } catch (err) {
      console.log(err);
      throw new Error("Error updating the information in the Smart Contract");
    }
  }

  async function pickImage(
    setImage: Dispatch<SetStateAction<ImagePicker.ImagePickerResult>>
  ) {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      quality: 1,
    });
    if (!result.canceled) {
      setImage(result);
    }
  }

  return (
    <>
      <ScrollView style={{ backgroundColor: "#151721" }}>
        <View style={styles.documents_container}>
          <View style={styles.field_container}>
            <View
              style={{ flexDirection: "row", alignItems: "center", gap: 8 }}
            >
              <Svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke={
                  username != null && username.length > 0 ? "#DB7BC6" : "white"
                }
              >
                <Path
                  d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <Path
                  d="M18.5 2.50001C18.8978 2.10219 19.4374 1.87869 20 1.87869C20.5626 1.87869 21.1022 2.10219 21.5 2.50001C21.8978 2.89784 22.1213 3.4374 22.1213 4.00001C22.1213 4.56262 21.8978 5.10219 21.5 5.50001L12 15L8 16L9 12L18.5 2.50001Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </Svg>
              <Text
                style={{
                  color: "white",
                  fontFamily: "Gilroy-Bold",
                  fontSize: 20,
                }}
              >
                Username
              </Text>
            </View>
            <View style={styles.username_input_container}>
              <TextInput
                style={styles.username_input}
                onChangeText={(text: string) => {
                  setUsername(text.trim());
                }}
                value={username}
                placeholder="Username"
                textAlignVertical="top"
                placeholderTextColor="#aaa"
              />
            </View>
          </View>
          <View style={{ gap: 8, marginTop: 8 }}>
            <View
              style={{ flexDirection: "row", gap: 8, alignItems: "center" }}
            >
              <Svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke={
                  imageFront.assets != null && imageBack.assets != null
                    ? "#DB7BC6"
                    : "white"
                }
              >
                <Path
                  d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <Path
                  d="M17 8L12 3L7 8"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <Path
                  d="M12 3V15"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </Svg>
              <Text
                style={{
                  color: "white",
                  fontFamily: "Gilroy-Bold",
                  fontSize: 20,
                }}
              >
                {t("becomeCreator.upload")}
              </Text>
            </View>
            <Switch
              value={isEnabled}
              onValueChange={toggleSwitch}
              disabled={false}
              activeText={t("common.front")}
              inActiveText={t("common.back")}
              circleSize={48}
              activeTextStyle={{
                width: "50%",
                flex: 1,
                textAlign: "center",
                fontFamily: "Gilroy-Bold",
              }}
              inactiveTextStyle={{
                width: "50%",
                flex: 1,
                textAlign: "center",
                fontFamily: "Gilroy-Bold",
              }}
              circleBorderWidth={4}
              circleBorderActiveColor="#232839"
              circleBorderInactiveColor="#232839"
              backgroundActive={"#232839"}
              backgroundInactive={"#232839"}
              circleActiveColor={"#DB7BC6"}
              circleInActiveColor={"#DB7BC6"}
              renderInsideCircle={() => (
                <>
                  {isEnabled ? (
                    <Text style={{ color: "white", fontFamily: "Gilroy-Bold" }}>
                      {t("common.back")}
                    </Text>
                  ) : (
                    <Text style={{ color: "white", fontFamily: "Gilroy-Bold" }}>
                      {t("common.front")}
                    </Text>
                  )}
                </>
              )}
              innerCircleStyle={{
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 24,
                width: "50%",
                flex: 1,
              }}
              outerCircleStyle={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flex: 1,
              }}
              switchLeftPx={200}
              switchRightPx={200}
              switchWidthMultiplier={(Dimensions.get("window").width - 32) / 48}
              switchBorderRadius={24}
            />
            {isEnabled ? (
              <>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    flex: 1,
                  }}
                >
                  <View
                    style={[
                      styles.front_document,
                      { padding: imageFront.assets == null ? 48 : 0 },
                    ]}
                  >
                    <View
                      style={{
                        display: "flex",
                        rowGap: 16,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Pressable
                        style={styles.upload_button_container}
                        onPress={() => {
                          pickImage(setImageFront);
                        }}
                      >
                        {imageFront.assets == null ? (
                          <>
                            <Svg
                              width={41}
                              height={41}
                              viewBox="0 0 41 41"
                              fill="none"
                            >
                              <Path
                                d="M13.8335 28.8333L20.5002 35.5L27.1668 28.8333"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <Path
                                d="M20.5 20.5V35.5"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <Path
                                d="M35.3001 30.65C36.7491 29.6311 37.8357 28.1769 38.4023 26.4986C38.9688 24.8202 38.9858 23.005 38.4508 21.3164C37.9158 19.6277 36.8566 18.1535 35.4269 17.1076C33.9973 16.0617 32.2715 15.4986 30.5001 15.5H28.4001C27.8989 13.5465 26.9609 11.7321 25.657 10.1935C24.353 8.65487 22.717 7.43211 20.8721 6.61727C19.0273 5.80242 17.0215 5.41672 15.006 5.4892C12.9905 5.56169 11.0177 6.09046 9.23605 7.03572C7.45445 7.98098 5.91051 9.31809 4.72045 10.9464C3.53038 12.5747 2.72522 14.4518 2.36557 16.4363C2.00592 18.4208 2.10115 20.461 2.6441 22.4034C3.18705 24.3458 4.16356 26.1396 5.50013 27.65"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </Svg>
                            <Text style={{ color: "white" }}>
                              {t("becomeCreator.choose_from_gallery")}
                            </Text>
                          </>
                        ) : (
                          <Image
                            source={{
                              uri:
                                imageFront.assets != null
                                  ? imageFront.assets[0].uri
                                  : "",
                            }}
                            style={{
                              width: "100%",
                              height: undefined,
                              aspectRatio: 1,
                              borderRadius: 16,
                            }}
                            resizeMode="cover"
                          />
                        )}
                      </Pressable>
                    </View>
                  </View>
                </View>
              </>
            ) : (
              <>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    flex: 1,
                  }}
                >
                  <View
                    style={[
                      styles.back_document,
                      { padding: imageBack.assets == null ? 48 : 0 },
                    ]}
                  >
                    <View
                      style={{
                        display: "flex",
                        rowGap: 16,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Pressable
                        style={styles.upload_button_container}
                        onPress={() => {
                          pickImage(setImageBack);
                        }}
                      >
                        {imageBack.assets == null ? (
                          <>
                            <Svg
                              width={41}
                              height={41}
                              viewBox="0 0 41 41"
                              fill="none"
                            >
                              <Path
                                d="M13.8335 28.8333L20.5002 35.5L27.1668 28.8333"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <Path
                                d="M20.5 20.5V35.5"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <Path
                                d="M35.3001 30.65C36.7491 29.6311 37.8357 28.1769 38.4023 26.4986C38.9688 24.8202 38.9858 23.005 38.4508 21.3164C37.9158 19.6277 36.8566 18.1535 35.4269 17.1076C33.9973 16.0617 32.2715 15.4986 30.5001 15.5H28.4001C27.8989 13.5465 26.9609 11.7321 25.657 10.1935C24.353 8.65487 22.717 7.43211 20.8721 6.61727C19.0273 5.80242 17.0215 5.41672 15.006 5.4892C12.9905 5.56169 11.0177 6.09046 9.23605 7.03572C7.45445 7.98098 5.91051 9.31809 4.72045 10.9464C3.53038 12.5747 2.72522 14.4518 2.36557 16.4363C2.00592 18.4208 2.10115 20.461 2.6441 22.4034C3.18705 24.3458 4.16356 26.1396 5.50013 27.65"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </Svg>
                            <Text style={{ color: "white" }}>
                              {t("becomeCreator.choose_from_gallery")}
                            </Text>
                          </>
                        ) : (
                          <Image
                            source={{
                              uri:
                                imageBack.assets != null
                                  ? imageBack.assets[0].uri
                                  : "",
                            }}
                            style={{
                              width: "100%",
                              height: undefined,
                              aspectRatio: 1,
                              borderRadius: 16,
                            }}
                            resizeMode="cover"
                          />
                        )}
                      </Pressable>
                    </View>
                  </View>
                </View>
              </>
            )}
          </View>
        </View>
      </ScrollView>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 16,
          backgroundColor: "#151721",
        }}
      >
        <Pressable
          onPress={handleSubmit}
          style={[
            styles.submit_button,
            { backgroundColor: isReadyToSubmit ? "#DB7BC6" : "#232839" },
          ]}
        >
          <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
            <Path
              d="M14.6999 6.29998C14.5166 6.48691 14.414 6.73823 14.414 6.99998C14.414 7.26173 14.5166 7.51305 14.6999 7.69998L16.2999 9.29998C16.4868 9.48321 16.7381 9.58584 16.9999 9.58584C17.2616 9.58584 17.5129 9.48321 17.6999 9.29998L21.4699 5.52998C21.9727 6.64117 22.1249 7.87921 21.9063 9.07913C21.6877 10.279 21.1086 11.3838 20.2461 12.2463C19.3837 13.1087 18.2789 13.6878 17.079 13.9064C15.8791 14.1251 14.641 13.9728 13.5299 13.47L6.61986 20.38C6.22203 20.7778 5.68246 21.0013 5.11986 21.0013C4.55725 21.0013 4.01768 20.7778 3.61986 20.38C3.22203 19.9822 2.99854 19.4426 2.99854 18.88C2.99854 18.3174 3.22203 17.7778 3.61986 17.38L10.5299 10.47C10.027 9.35879 9.87477 8.12075 10.0934 6.92083C10.312 5.72092 10.8911 4.61614 11.7536 3.7537C12.616 2.89127 13.7208 2.31215 14.9207 2.09352C16.1206 1.8749 17.3587 2.02714 18.4699 2.52998L14.7099 6.28998L14.6999 6.29998Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Svg>
          <Text style={{ color: "white" }}>{t("becomeCreator.send")}</Text>
        </Pressable>
      </View>
    </>
  );
}
const styles = StyleSheet.create({
  documents_container: {
    flex: 1,
    display: "flex",
    margin: 16,
    rowGap: 16,
    height: "100%",
  },
  front_document: {
    width: "100%",
    aspectRatio: 1,
    backgroundColor: "#232839",
    borderRadius: 16,
    padding: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  back_document: {
    width: "100%",
    aspectRatio: 1,
    backgroundColor: "#232839",
    borderRadius: 16,
    padding: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  document_background_image: {
    flex: 1,
    width: "100%",
    resizeMode: "cover",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  button: {
    width: "100%",
    padding: 12,
    backgroundColor: "#DB7BC6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 16,
  },
  submit_button: {
    paddingVertical: 12,
    width: "80%",
    backgroundColor: "#5F5F5F",
    flexDirection: "row",
    gap: 8,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 16,
  },
  username_input_container: {
    backgroundColor: "transparent",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 8,
  },
  username_input: {
    color: "white",
    backgroundColor: "transparent",
    flex: 1,
    alignSelf: "flex-start",
  },
  upload_button_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    rowGap: 8,
    alignSelf: "stretch",
  },
  field_container: {
    flex: 1,
    gap: 8,
    padding: 16,
    borderRadius: 16,
    backgroundColor: "#232839",
  },
});
