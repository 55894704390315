import { CreatorType } from "../../types/creator/Creator";
import { View, Image, StyleSheet, Text, Pressable } from "react-native";
import React from "react";
import { APP_SERVER_URL } from "../../configuration/config";

export default function HeaderContent({
  creator,
  navigation,
}: {
  creator: CreatorType;
  navigation: any;
}) {
  function navigation_() {
    navigation.navigate("Creator", {
      address: creator.address.slice(2),
      name: creator.name,
    });
  }
  return (
    <>
      <Pressable onPress={navigation_}>
        <View style={styles.header_container}>
          <Image
            style={styles.header_image}
            source={{
              uri: `${APP_SERVER_URL}/profiles/${creator.profileImageURL}`,
            }}
          />
          <Text style={styles.header_name}>{creator.name}</Text>
        </View>
      </Pressable>
    </>
  );
}
const styles = StyleSheet.create({
  header_container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
    paddingHorizontal: 16,
  },
  header_image: {
    width: 40,
    height: 40,
    borderRadius: 30,
  },
  header_name: {
    color: "#fff",
    fontSize: 16,
    fontFamily: "Gilroy-Bold",
  },
});
