import React, { useEffect, useRef } from "react";
import {
	View,
	Modal,
	StyleSheet,
	Text,
	ActivityIndicator,
	Animated,
} from "react-native";
import icon from "../../assets/icon.png";

export default function LoadingComponent({
	isLoading,
}: {
	isLoading: boolean;
}) {
	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={isLoading}
			statusBarTranslucent={true}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<ActivityIndicator size="large" color={"#DB7BC6"} />
					<Text style={styles.modalText}>Loading</Text>
				</View>
				{/* <Animated.Image style={[styles.image, [{ transform: [{ rotate: spin }] }]]} source={icon}></Animated.Image> */}
			</View>
		</Modal>
	);
}

const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#000a",
	},
	modalView: {
		margin: 20,
		width: 200,
		height: 70,
		backgroundColor: "black",
		borderRadius: 5,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	image: {
		width: 60,
		height: undefined,
		aspectRatio: 1,
	},

	modalText: {
		color: "white",
		marginVertical: 15,
		textAlign: "center",
		fontSize: 17,
		marginLeft: 15,
	},
});
