// Test
// const APP_SERVER_URL = "http://localhost:8000";
// const DAPP_URL = "http://localhost:19006";
// const RPC_URL = "https://rpc-amoy.polygon.technology";
// const NETLI_OF_CONTRACT_ADDRESS = "0x2B5679E19c34154E750Ec09649cFcA9ead68DBfe";

// Production
const APP_SERVER_URL = "https://app.netlyfans.com";
const DAPP_URL = "https://app.netlyfans.com";
const RPC_URL = "https://polygon-rpc.com";
const NETLI_OF_CONTRACT_ADDRESS = "0x76BF318736B8356b670C324E895413b4f6CF91F8"; // --> OLD CONTRACT ADDRESS
// const NETLI_OF_CONTRACT_ADDRESS = "0x71039998de7686a1632c7d2c965aba0efbcb6443"; // --> NEW CONTRACT ADDRESS

const TEST_CREATORS = [
  "asdads2222212",
  "sdaas23sd",
  "netlyfansaasdas",
  "netlyfans12dsa3",
  "hj78j",
  "dadasda42d1",
  "asd2asd3asd2asp",
  "adasda9ds9s",
  "adadsdasdad222a",
  "adadsdasdad2",
  "alexa9",
  "alexa99",
];

export {
  APP_SERVER_URL,
  DAPP_URL,
  RPC_URL,
  NETLI_OF_CONTRACT_ADDRESS,
  TEST_CREATORS,
};
