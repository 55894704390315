import { createContext, useContext, useState } from "react";
import "react-native-get-random-values";

import "@ethersproject/shims";
import { ethers } from "ethers";

import AsyncStorageStatic from "@react-native-async-storage/async-storage";
import CryptoJS from "react-native-crypto-js";

import { WalletContext } from "../types/providers/WalletProvider";
import { WalletInfoType } from "../types/providers/Wallet";
import { useAuthenticationContext } from "./AuthenticationProvider";

import * as SecureStore from "expo-secure-store";
import { useLoadingContext } from "./LoadingProvider";

const WalletContextImpl = createContext<WalletContext>({} as WalletContext);
export const WalletProvider = ({ children }: { children: JSX.Element }) => {
	const [walletInfo, setWalletInfo] = useState<WalletInfoType | null>(null);
	const { setIsLoading } = useLoadingContext();

	const createWallet = async (
		pin: string,
		useBiometrics: boolean
	): Promise<void> => {
		setIsLoading(true);
		try {
			await AsyncStorageStatic.setItem(
				"@useBiometrics",
				useBiometrics ? "true" : "false"
			);
			const wallet = ethers.Wallet.createRandom();

			if (wallet.mnemonic == null) {
				throw new Error("Wallet creation failed");
			}

			const walletInfo: WalletInfoType = {
				address: wallet.address,
				mnemonic: wallet.mnemonic.phrase,
				privateKey: wallet.privateKey,
			};

			await saveWalletLocally(
				walletInfo.mnemonic,
				walletInfo.privateKey,
				pin
			);
			if (useBiometrics) await saveWalletInSecureStorage(walletInfo);
			setWalletInfo(walletInfo);
			setIsLoading(false);
		} catch (err) {
			const error = err as Error;
			setIsLoading(false);
			console.log(error);
		}
	};

	const saveWalletLocally = async (
		mnemonic: string,
		privateKey: string,
		pin: string
	) => {
		const byte = CryptoJS.AES.encrypt(
			JSON.stringify({ mnemonic: mnemonic, privateKey: privateKey }),
			pin
		).toString();

		await AsyncStorageStatic.setItem("@walletInfo", byte);
	};
	const getWalletLocally = async (pin: string) => {
		const walletSecretsBytes = await AsyncStorageStatic.getItem(
			"@walletInfo"
		);
		if (walletSecretsBytes == null) {
			throw new Error("Unable to get the wallet information");
		}
		const bytes = CryptoJS.AES.decrypt(walletSecretsBytes, pin);
		let data;
		try {
			data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		} catch (err) {
			console.log(err);
			throw new Error("Unable to get the wallet information");
		}
		const walletInfo = walletFromMnemonic(data.mnemonic);
		return {
			mnemonic: data.mnemonic,
			privateKey: data.privateKey,
			address: walletInfo.address,
		} as WalletInfoType;
	};

	const saveWalletInSecureStorage = async (walletInfo: WalletInfoType) => {
		await SecureStore.setItemAsync("walletMnemonic", walletInfo.mnemonic);
		await SecureStore.setItemAsync(
			"walletPrivateKey",
			walletInfo.privateKey
		);
	};

	const getWalletFromSecureStorage = async (): Promise<WalletInfoType> => {
		const mnemonic_ = await SecureStore.getItemAsync("walletMnemonic");
		const privatekey_ = await SecureStore.getItemAsync("walletPrivateKey");
		if (mnemonic_ == null || privatekey_ == null) {
			throw new Error("Unable to get the wallet information");
		}
		const walletInfo = walletFromMnemonic(mnemonic_);

		setWalletInfo(walletInfo);
		return walletInfo;
	};
	const getWalletAddress = async () => {
		let address: string | null = "";
		try {
			if (walletInfo == null || walletInfo.address == null) {
				throw new Error("Unable to get the wallet address");
			}
			address = walletInfo.address;
		} catch (err) {
			const error = err as Error;
			if (error.message === "Unable to get the wallet address")
				throw new Error("Unable to get the wallet address");
			throw new Error("Something went wrong");
		}

		return address;
	};
	const getWalletInformation = async (
		pin: string
	): Promise<WalletInfoType> => {
		let walletInfo_: WalletInfoType = {} as WalletInfoType;
		setIsLoading(true);
		try {
			walletInfo_ = await getWalletLocally(pin);

			setWalletInfo(walletInfo_);
			setIsLoading(false);
		} catch (err) {
			console.log(err);
			setIsLoading(false);
		}
		return walletInfo_;
	};

	const loadWalletFromMnemonic = async (
		mnemonic: string,
		pin: string,
		useBiometrics: boolean
	) => {
		await AsyncStorageStatic.setItem(
			"@useBiometrics",
			useBiometrics ? "true" : "false"
		);
		const walletInfo = walletFromMnemonic(mnemonic);
		await saveWalletLocally(
			walletInfo.mnemonic,
			walletInfo.privateKey,
			pin
		);
		if (useBiometrics) await saveWalletInSecureStorage(walletInfo);
		setWalletInfo(walletInfo);
	};
	const walletFromMnemonic = (mnemonic: string): WalletInfoType => {
		let walletInfo: WalletInfoType = {
			address: "",
			mnemonic: "",
			privateKey: "",
		};
		let mnemonicWallet = ethers.Wallet.fromMnemonic(mnemonic);

		walletInfo = {
			address: mnemonicWallet.address,
			mnemonic: mnemonicWallet.mnemonic?.phrase as string,
			privateKey: mnemonicWallet.privateKey,
		};

		return walletInfo;
	};
	const isCorrectPin = async (pin: string) => {
		let isCorrect = false;
		try {
			const walletInfo_ = await getWalletLocally(pin);
			const wallet = walletFromMnemonic(walletInfo_.mnemonic);

			if (wallet.address === walletInfo?.address) {
				isCorrect = true;
			}
		} catch (err) {
			console.log(err);
		}
		return isCorrect;
	};
	const deleteAccount = async () => {
		try {
			const useBiometrics = await AsyncStorageStatic.getItem(
				"@useBiometrics"
			);
			await AsyncStorageStatic.removeItem("@walletInfo");
			await AsyncStorageStatic.removeItem("@useBiometrics");
			await AsyncStorageStatic.removeItem("categoriesSelected");

			if (useBiometrics != null && useBiometrics === "true") {
				await SecureStore.deleteItemAsync("walletMnemonic");
				await SecureStore.deleteItemAsync("walletPrivateKey");
			}
			setWalletInfo(null);
		} catch (err) {
			console.log(err);
		}
	};

	const values = {
		createWallet,
		saveWalletLocally,
		getWalletAddress,
		getWalletInformation,
		loadWalletFromMnemonic,
		walletInfo,
		setWalletInfo,
		getWalletFromSecureStorage,
		isCorrectPin,
		deleteAccount,
	};

	return (
		<WalletContextImpl.Provider value={values}>
			{children}
		</WalletContextImpl.Provider>
	);
};

export const useWalletContext = () => useContext(WalletContextImpl);
