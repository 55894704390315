import { useState, useEffect } from "react";
import {
  View,
  Modal,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  Pressable,
  TouchableOpacity,
  TextInput,
  Platform,
} from "react-native";
import { Feather } from "@expo/vector-icons";

import * as Clipboard from "expo-clipboard";
import { A } from "@expo/html-elements";
import { useTranslation } from "react-i18next";
import { useBlockchainContext } from "../../providers/BlockchainProvider";
import { useSnackbar } from "../../providers/SnackBarProvider";

export default function SendMaticModal({
  isOpen,
  handleSendMatic,
  handleBack,
}: {
  isOpen: boolean;
  handleSendMatic: (address: string, amount: number) => Promise<void>;
  handleBack: () => void;
}) {
  const { t } = useTranslation();
  const [addressToSend, setAddressToSend] = useState<string>("");
  const [maticAmount, setMaticAmount] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { isAddressValid } = useBlockchainContext();
  const { openSnackbar } = useSnackbar();
  function checkValidAddress(address: string) {
    let isValid = false;
    try {
      isValid = isAddressValid(address);
    } catch (err) {
      console.error(err);
    }
    return isValid;
  }

  function handleSubmit() {
    const validAddress = checkValidAddress(addressToSend);
    if (validAddress && !isNaN(Number(maticAmount))) {
      handleSendMatic(addressToSend, Number(maticAmount));
    } else {
      if (isNaN(Number(maticAmount))) {
        openSnackbar("Error", t("errors.notANumber"), "error");
      } else {
        openSnackbar("Error", t("errors.invalidAddress"), "error");
      }
    }
    handleBack();
  }
  return (
    <>
      <Modal
        animationType="fade"
        transparent={true}
        visible={isOpen}
        statusBarTranslucent={false}
        onRequestClose={() => {
          handleBack();
        }}
      >
        <TouchableWithoutFeedback
          style={styles.centeredView}
          onPress={() => {
            if (!isFocused) handleBack();
            setIsFocused(false);
          }}
        >
          <View style={styles.centeredView}>
            <View
              style={styles.modalView}
              onTouchStart={() => {
                setIsFocused(true);
              }}
            >
              <Text
                style={{
                  fontSize: 24,
                  fontFamily: "Gilroy-Bold",
                  color: "white",
                }}
              >
                {t("sendMatic.title")}
              </Text>
              <View style={styles.input_container}>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    fontSize: 12,
                    color: "white",
                  }}
                >
                  {t("sendMatic.address")}
                </Text>
                <TextInput
                  style={styles.address_input}
                  onChangeText={(text: string) => {
                    setAddressToSend(text.trim());
                  }}
                  value={addressToSend}
                  placeholder={t("common.address")}
                  textAlignVertical="top"
                  placeholderTextColor="#aaa"
                />
              </View>
              <View style={styles.input_container}>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    fontSize: 12,
                    color: "white",
                  }}
                >
                  {t("sendMatic.matic")}
                </Text>
                <TextInput
                  keyboardType={
                    Platform.OS === "android" ? "numeric" : "decimal-pad"
                  }
                  style={styles.price_input}
                  onChangeText={(amount) => {
                    setMaticAmount(amount);
                  }}
                  value={maticAmount}
                  placeholder="Polygon"
                  textAlignVertical="center"
                  placeholderTextColor="#aaa"
                />
              </View>
              <View style={{ justifyContent: "center", width: "100%" }}>
                <Pressable
                  onPress={handleSubmit}
                  style={{
                    borderRadius: 16,
                    paddingHorizontal: 32,
                    backgroundColor: "#DB7BC6",
                    alignSelf: "flex-end",
                    padding: 8,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Gilroy-Bold",
                      fontSize: 16,
                      color: "white",
                    }}
                  >
                    {t("becomeCreator.send")}
                  </Text>
                </Pressable>
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </>
  );
}
const styles = StyleSheet.create({
  centeredView: {
    position: "relative",
    height: "100%",
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000a",
    padding: 16,
  },
  modalView: {
    display: "flex",
    gap: 16,
    width: "80%",
    padding: 16,
    backgroundColor: "#151721",
    borderRadius: 16,
    justifyContent: "center",
    alignItems: "center",
    rowGap: 16,
  },

  address_input: {
    color: "white",
    backgroundColor: "transparent",
    flex: 1,
    width: "100%",
  },
  price_input: {
    color: "white",
    backgroundColor: "transparent",
    flex: 1,
    width: "100%",
  },
  input_container: {
    backgroundColor: "#232839",
    width: "100%",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    borderRadius: 16,
    padding: 8,
    rowGap: 4,
  },
});
