import "react-native-get-random-values";
import React, { useEffect, useCallback, useState } from "react";
import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  View,
  Platform,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import {
  NavigationContainer,
  useNavigationContainerRef,
} from "@react-navigation/native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import * as Linking from "expo-linking";
import Provider from "./src/providers/Providers";
import Root from "./src/Root";
import "react-native-gesture-handler";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { isMobile } from "react-device-detect";
import { enableExperimentalWebImplementation } from "react-native-gesture-handler";
import {
  RedirectionProvider,
  useRedirectionContext,
} from "./src/providers/RedirectionProvider";
import { DAPP_URL } from "./src/configuration/config";

enableExperimentalWebImplementation(true);

if (Platform.OS !== "web") {
  SplashScreen.preventAutoHideAsync();
}

const config = {
  screens: {
    Home: "home",
    Creator: "user",
    Purchase: "purchase",
    Receive: "receive",
    "Become A Creator": "become",
    "Wallet Information": "wallet",
    "Post Content": "post",
    "Modify Profile": "modify",
  },
};
const prefix = Linking.createURL("/");
const linking = {
  prefixes: [prefix, `${DAPP_URL}/`, DAPP_URL],
  config,
};
export default function App() {
  return (
    <>
      <RedirectionProvider>
        <App_ />
      </RedirectionProvider>
    </>
  );
}
function App_() {
  const navigationRef = useNavigationContainerRef(); // You can also use a regular ref with `React.useRef()`
  const url = Linking.useURL();
  const [creatorName, setCreatorName] = useState<string | null | undefined>(
    null
  );
  const [creatorAddress, setCreatorAddress] = useState<
    string | null | undefined
  >(null);
  const { setHasParams } = useRedirectionContext();
  const [isSmallScreen, setIsSmallScreen] = useState(
    Platform.OS === "web" && !isMobile && Dimensions.get("window").width < 1024
  );
  const handleURL = (url: string) => {
    const { hostname, path, queryParams } = Linking.parse(url);
    if (path === "records/user") {
      if (queryParams == null) {
        console.error("invalid IRL");
      } else {
        const params = [
          "Creator",
          { name: queryParams["name"], address: queryParams["address"] },
        ];
        setCreatorName(queryParams["name"] as string);
        setCreatorAddress(queryParams["address"] as string);
        setHasParams(true);
        // navigationRef.navigate([...params] as any as never);
      }
    }
  };

  useEffect(() => {
    if (url == null) return;
    handleURL(url);
  }, [url]);
  const [fontsLoaded, error] = useFonts({
    "Gilroy-Bold": require("./src/assets/fonts/Gilroy-Bold.ttf"),
  });

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(
        Platform.OS === "web" &&
          !isMobile &&
          Dimensions.get("window").width < 1024
      );
    };

    const subscription = Dimensions.addEventListener("change", handleResize);
    return () => subscription?.remove();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <>
      <Provider>
        <SafeAreaProvider>
          <NavigationContainer
            ref={navigationRef}
            linking={linking}
            fallback={
              <View
                style={{
                  width: "100%",
                  height: "100%",
                  // borderRadius: 32,
                  backgroundColor: "#151721",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator
                  style={{ flex: 1 }}
                  size={80}
                  color="#DB7BC6"
                />
              </View>
            }
          >
            <View
              style={
                Platform.OS !== "web" || isMobile || isSmallScreen
                  ? styles.container
                  : styles.containerDesktop
              }
              onLayout={onLayoutRootView}
            >
              <StatusBar backgroundColor="#151721" />
              <Root
                creatorName={creatorName}
                creatorAddress={creatorAddress}
                navigation={navigationRef}
              />
            </View>
          </NavigationContainer>
        </SafeAreaProvider>
      </Provider>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#151721",
    maxWidth: 1024,
  },
  containerDesktop: {
    flex: 1,
    width: "50%",
    alignSelf: "center",
    backgroundColor: "#151721",
  },
});
