import {
  View,
  ImageBackground,
  Image,
  StyleSheet,
  Text,
  Pressable,
} from "react-native";

import { CreatorType } from "../../types/creator/Creator";
import { APP_SERVER_URL } from "../../configuration/config";
import React from "react";

export default function ProfileSearchComponent({
  creator,
  navigation,
}: {
  creator: CreatorType;
  navigation: any;
}) {
  return (
    <>
      <View style={styles.container}>
        <ImageBackground
          resizeMode="cover"
          style={styles.background_image}
          source={{
            uri: `${APP_SERVER_URL}/profiles/${creator.coverImageURL}`,
          }}
          blurRadius={8}
          borderRadius={16}
        ></ImageBackground>
        <Pressable
          onPress={() => {
            navigation.navigate("Creator", {
              address: creator.address.slice(2),
              name: creator.name,
            });
          }}
          style={styles.creator_information_container}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              gap: 16,
            }}
          >
            <Image
              source={{
                uri: `${APP_SERVER_URL}/profiles/${creator.profileImageURL}`,
              }}
              style={styles.profile_image}
            />
            <Text style={{ fontWeight: "bold", fontSize: 24, color: "white" }}>
              {creator.name}
            </Text>
          </View>
        </Pressable>
      </View>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    flex: 1,
    minHeight: 100,
    display: "flex",
    borderRadius: 16,
    position: "relative",
    overflow: "hidden",
  },
  background_image: {
    position: "absolute",
    width: "100%",
    height: "100%",
    bottom: 0,
    left: 0,
    resizeMode: "cover",
    zIndex: 1,
  },
  creator_information_container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    zIndex: 2,
    padding: 16,
    paddingVertical: 16,
  },

  profile_image: {
    height: 80,
    width: 80,
    borderRadius: 40,
    borderColor: "#DB7BC6",
    borderWidth: 4,
    alignSelf: "center",
  },
});
