import { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import {
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  TextInput,
} from "react-native";

import { Pressable, Image, Text, View } from "react-native";
import * as ImagePicker from "expo-image-picker";
import * as FileSystem from "expo-file-system";
import { useContentContext } from "../../providers/ContentProvider";
import DropDownPicker from "react-native-dropdown-picker";
import { useNetwork } from "../../providers/NetworkProvider";
import FormData from "form-data";
import { useWalletContext } from "../../providers/WalletProvider";
import { useBlockchainContext } from "../../providers/BlockchainProvider";
import { useLoadingContext } from "../../providers/LoadingProvider";
import { parseCookiesPostContnet } from "../../utils/Cookies/ParseCookies";
import { useSnackbar } from "../../providers/SnackBarProvider";
import { Switch } from "react-native-switch";
import { G, Path, Svg, SvgXml } from "react-native-svg";

import { Feather } from "@expo/vector-icons";
import UploadLoading from "../../components/loading/UploadLoading";
import { useTranslation } from "react-i18next";
import { fileTypeToExtension, isPhoto } from "../../utils/imageController";

type CategoryForPickerType = {
  label: string;
  value: number;
};

export default function PostContent() {
  //For Mobile
  const [paidConentPicker, setPaidContentPicker] =
    useState<ImagePicker.ImagePickerResult>(
      {} as ImagePicker.ImagePickerResult
    );
  const [freeImage, setFreeImage] = useState<ImagePicker.ImagePickerResult>(
    {} as ImagePicker.ImagePickerResult
  );
  //For web
  const [premiumContent, setPremiumContent] = useState<File | null>(null);
  const [freeContent, setFreeContent] = useState<File | null>(null);

  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<string>("");

  const [open, setOpen] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [categoriesForPicker, setCategoriesForPicker] = useState<
    CategoryForPickerType[]
  >([]);
  const [isReadyToPost, setIsReadyToPost] = useState<boolean>(true);
  const { categories } = useContentContext();
  const { makePostContent } = useNetwork();
  const { walletInfo } = useWalletContext();
  const { postContent, getNumberOfContentOfCreator, getWeiFromMatic } =
    useBlockchainContext();
  const { setIsLoading } = useLoadingContext();
  const { openSnackbar } = useSnackbar();
  const [isEnabled, setIsEnabled] = useState(false);
  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);
  const [progress, setProgress] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsSubmitting] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const { t } = useTranslation();
  useEffect(() => {
    const categoryForPicker = [];
    for (let i = 0; i < categories.length; i++) {
      categoryForPicker.push({
        label: categories[i].name,
        value: categories[i].id,
      });
    }
    setCategoriesForPicker(categoryForPicker);
  }, []);
  useEffect(() => {
    const hasFilleAllInputs = hasFilledAllInputs();
    setIsReadyToPost(hasFilleAllInputs);
  }, [
    selectedCategory,
    description,
    price,
    premiumContent,
    paidConentPicker.assets,
    freeImage.assets,
    freeContent,
  ]);

  function hasFilledAllInputs(): boolean {
    let hasFilledAllInputs = false;
    if (
      selectedCategory != 0 &&
      description.length > 0 &&
      price.length > 0 &&
      (premiumContent != null || paidConentPicker.assets != null) &&
      (freeImage.assets != null || freeContent != null)
    ) {
      hasFilledAllInputs = true;
    }
    return hasFilledAllInputs;
  }
  function uploadProgress(progress: number, total_: number) {
    setProgress(progress);
    if (total === 0) setTotal(total_);
  }
  async function handleUploadToStorage() {
    let response = {
      previewUrl: "",
      paidUrl: "",
      error: false,
      errMessage: "",
    };
    if (walletInfo == null) {
      response.errMessage = t("errors.reconnect_wallet");
      response.error = true;
      return response;
    }
    try {
      setTotal(0);
      let freeUri: string = "";
      let paidUri: string = "";
      let paidMediatype: string = "image";
      let formData = new FormData();

      const cCount = await getNumberOfContentOfCreator(walletInfo.address);

      if (Platform.OS !== "web") {
        if (freeImage.assets != null) {
          freeUri = freeImage.assets[0].uri;
        }
        if (paidConentPicker.assets != null) {
          paidUri = paidConentPicker.assets[0].uri;
          if (paidConentPicker.assets[0].type != null)
            paidMediatype = paidConentPicker.assets[0].type;
        }
        const freeType = freeUri.split(".").slice(-1)[0];
        const paidType = paidUri.split(".").slice(-1)[0];
        formData.append("fileToUpload", {
          uri: freeUri,
          name: "front." + freeType,
          type: "image/" + freeType,
        });
        formData.append("fileToUpload2", {
          uri: paidUri,
          name: "back." + paidType,
          type: paidMediatype + "/" + paidType,
        });
      } else {
        if (freeContent != null) {
          formData.append("fileToUpload", freeContent);
        }
        if (premiumContent != null) {
          formData.append("fileToUpload2", premiumContent);
        }
      }
      formData.append("desc", description);
      formData.append("mobile", true);
      formData.append("price", 10);
      formData.append("cCount", cCount);
      formData.append("category", 1);
      formData.append("user", walletInfo.address);

      const response_ = await makePostContent(
        "/upload.php",
        formData,
        {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        } as any,
        uploadProgress
      );

      let responseCookie = { errid: 0, File1: "", File2: "" };
      const data_ = String(response_.data);
      if (data_.length === 1) {
        responseCookie.errid = Number(data_);
      } else {
        const data = data_.split(";");
        responseCookie.File1 = String(data[0]);
        responseCookie.File2 = String(data[1]);
        responseCookie.errid = Number(data[data.length - 1]);
      }

      if (
        responseCookie.errid === 0 &&
        responseCookie.File2 != null &&
        responseCookie.File1 != null
      ) {
        if (responseCookie.File2.split(".").slice(-1)[0] === "mov") {
          response.paidUrl = responseCookie.File2.split(".")[0] + ".mp4";
        } else {
          response.paidUrl = responseCookie.File2;
        }
        response.previewUrl = responseCookie.File1;
      } else {
        response.error = true;
        switch (responseCookie.errid) {
          case 1:
            response.errMessage = t("errors.free_content_exeeds_10MB");
            break;
          case 2:
            response.errMessage = t("errors.paid_content_exeeds_2GB");
            break;
          case 3:
            response.errMessage = t("errors.free_image_format_not_supported");
            break;
          case 4:
            response.errMessage = t("errors.paid_content_format_not_supported");
            break;
          case 5:
            response.errMessage = t(
              "errors.something_went_wrong_during_file_saving"
            );
            break;
          default:
            response.errMessage = t("errors.weird_contact_us");
        }
      }
      // response.error = true;
    } catch (err) {
      console.log(err);
      response.errMessage = t("errors.weird_contact_us");
      response.error = true;
    }
    return response;
  }
  async function handleUploadInfoToSC(
    previewUrl: string,
    paidUrl: string,
    price: string
  ) {
    try {
      await postContent(
        price,
        description,
        selectedCategory,
        previewUrl,
        paidUrl
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function handleSubmit() {
    setMessage(t("postContnet.upload_to_server"));
    const price_ = getWeiFromMatic(Number(price));
    setIsSubmitting(true);
    const result = await handleUploadToStorage();
    if (!result.error) {
      setMessage(t("postContent.upload_to_smart_contract"));
      await handleUploadInfoToSC(result.previewUrl, result.paidUrl, price_);
      openSnackbar(
        t("common.success"),
        t("postContent.upload_successfull"),
        "success"
      );
    } else {
      openSnackbar(t("common.error"), result.errMessage, "error");
    }
    setIsSubmitting(false);
  }

  async function pickFreeContent() {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
    });
    setIsLoading(true);

    if (!result.canceled) {
      if (Platform.OS === "web") {
        const base64 = result.assets[0].uri;
        const res = await fetch(base64);
        const blob = await res.blob();
        const file = new File(
          [blob],
          "freePhoto" + fileTypeToExtension(blob.type),
          { type: blob.type }
        );
        setFreeContent(file);
      } else {
        setFreeImage(result);
      }
    }
    setIsLoading(false);
  }

  async function pickPremiumContent() {
    // setTimeout(() => {
    // 	setIsLoading(false);
    // }, 120000);
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
      });

      if (!result.canceled) {
        if (Platform.OS === "web") {
          const base64 = result.assets[0].uri;
          const fileType = base64.split(";")[0].split(":")[1];
          // console.log(fileType);
          const byteString = atob(base64.split(",")[1]);

          const ab = new ArrayBuffer(byteString.length);
          const arr = new Uint8Array(ab);

          for (let i = 0; i < byteString.length; i++)
            arr[i] = byteString.charCodeAt(i);

          // const res = await fetch(base64);
          const blob = new Blob([arr], { type: fileType });
          const file = new File(
            [blob],
            "premiumContent" + fileTypeToExtension(blob.type),
            { type: blob.type }
          );

          setPremiumContent(file);
        } else {
          setPaidContentPicker(result);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  function handleCloseLoading() {
    setIsSubmitting(false);
    setIsError(false);
    setMessage("");
  }
  return (
    <>
      <UploadLoading
        message={message}
        handleClose={handleCloseLoading}
        isLoading={isLoading}
        error={isError}
        progress={progress}
        total={total}
      />

      <ScrollView style={{ backgroundColor: "#151721", flex: 1 }}>
        <View style={styles.documents_container}>
          <View
            style={{
              backgroundColor: "#232839",
              borderRadius: 16,
              flexDirection: "row",
              alignItems: "center",
              paddingVertical: 16,
              paddingHorizontal: 24,
              width: "100%",
              gap: 0,
            }}
          >
            {/* <SvgXml height={24} width={24} stroke={selectedCategory != 0 ? "#DB7BC6" : "white"} xml={categories_icon} /> */}
            <Svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              stroke={selectedCategory != 0 ? "#DB7BC6" : "white"}
              fill="none"
            >
              <G id="grid">
                <Path
                  d="M10 3H3V10H10V3Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <Path
                  d="M21 3H14V10H21V3Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <Path
                  d="M21 14H14V21H21V14Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <Path
                  d="M10 14H3V21H10V14Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </G>
            </Svg>
            <DropDownPicker
              open={open}
              value={selectedCategory}
              items={categoriesForPicker}
              setOpen={setOpen}
              setValue={setSelectedCategory}
              theme="DARK"
              placeholder={t("postContent.category")}
              placeholderStyle={{
                color: "white",
                fontFamily: "Gilroy-Bold",
                fontSize: 20,
              }}
              searchable={true}
              listMode="MODAL"
              style={{
                flex: 1,
                backgroundColor: "#232839",
                borderRadius: 8,
                borderWidth: 0,
                flexGrow: 0,
              }}
              containerStyle={{ flex: 1, flexGrow: 1 }}
              textStyle={{
                color: "white",
                fontFamily: "Gilroy-Bold",
                fontSize: 20,
              }}
              searchPlaceholder={t("postContent.search_category")}
              listItemContainerStyle={{ paddingHorizontal: 16 }}
              modalContentContainerStyle={{ backgroundColor: "#151721" }}
              translation={{ NOTHING_TO_SHOW: t("postContent.no_cat_found") }}
            />
          </View>
          <View style={styles.description_container}>
            <View
              style={{ flexDirection: "row", alignItems: "center", gap: 8 }}
            >
              {/* <SvgXml stroke={description.length > 0 ? "#DB7BC6" : "white"} xml={edit_icon} /> */}
              <Svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke={description.length > 0 ? "#DB7BC6" : "white"}
              >
                <Path
                  d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <Path
                  d="M18.5 2.50001C18.8978 2.10219 19.4374 1.87869 20 1.87869C20.5626 1.87869 21.1022 2.10219 21.5 2.50001C21.8978 2.89784 22.1213 3.4374 22.1213 4.00001C22.1213 4.56262 21.8978 5.10219 21.5 5.50001L12 15L8 16L9 12L18.5 2.50001Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </Svg>
              <Text
                style={{
                  color: "white",
                  fontFamily: "Gilroy-Bold",
                  fontSize: 20,
                }}
              >
                {t("postContent.description")}
              </Text>
            </View>
            <View style={styles.input_container}>
              <TextInput
                multiline={true}
                numberOfLines={8}
                style={styles.description_input}
                onChangeText={(desc) => {
                  if (desc.length < 150) {
                    setDescription(desc);
                  }
                }}
                value={description}
                placeholder={t("postContent.description")}
                textAlignVertical="top"
                placeholderTextColor="#c2c2c2"
              />
            </View>
            <Text
              style={{
                color: "white",
                fontFamily: "Gilroy-Bold",
                alignSelf: "flex-end",
              }}
            >
              {description.length}/150
            </Text>
          </View>
          <View style={styles.price_container}>
            <View
              style={{ flexDirection: "row", alignItems: "center", gap: 8 }}
            >
              <Svg height={24} width={24} viewBox="0 0 38.4 33.5">
                <G>
                  <Path
                    fill={price.length > 0 ? "#DB7BC6" : "white"}
                    d="M29,10.2c-0.7-0.4-1.6-0.4-2.4,0L21,13.5l-3.8,2.1l-5.5,3.3c-0.7,0.4-1.6,0.4-2.4,0L5,16.3   c-0.7-0.4-1.2-1.2-1.2-2.1v-5c0-0.8,0.4-1.6,1.2-2.1l4.3-2.5c0.7-0.4,1.6-0.4,2.4,0L16,7.2c0.7,0.4,1.2,1.2,1.2,2.1v3.3l3.8-2.2V7   c0-0.8-0.4-1.6-1.2-2.1l-8-4.7c-0.7-0.4-1.6-0.4-2.4,0L1.2,5C0.4,5.4,0,6.2,0,7v9.4c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7   c0.7,0.4,1.6,0.4,2.4,0l5.5-3.2l3.8-2.2l5.5-3.2c0.7-0.4,1.6-0.4,2.4,0l4.3,2.5c0.7,0.4,1.2,1.2,1.2,2.1v5c0,0.8-0.4,1.6-1.2,2.1   L29,28.8c-0.7,0.4-1.6,0.4-2.4,0l-4.3-2.5c-0.7-0.4-1.2-1.2-1.2-2.1V21l-3.8,2.2v3.3c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7   c0.7,0.4,1.6,0.4,2.4,0l8.1-4.7c0.7-0.4,1.2-1.2,1.2-2.1V17c0-0.8-0.4-1.6-1.2-2.1L29,10.2z"
                  />
                </G>
              </Svg>
              <Text
                style={{
                  color: "white",
                  fontSize: 20,
                  fontFamily: "Gilroy-Bold",
                }}
              >
                Polygon
              </Text>
            </View>
            <View style={styles.input_container}>
              <TextInput
                keyboardType={
                  Platform.OS === "android" ? "numeric" : "decimal-pad"
                }
                style={styles.price_input}
                onChangeText={setPrice}
                value={price}
                placeholder="Polygon"
                textAlignVertical="center"
                placeholderTextColor="#aaa"
              />
            </View>
          </View>

          <View style={{ gap: 8, marginTop: 8 }}>
            <View
              style={{ flexDirection: "row", gap: 8, alignItems: "center" }}
            >
              <Svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke={
                  paidConentPicker.assets != null && freeImage.assets != null
                    ? "#DB7BC6"
                    : "white"
                }
              >
                <Path
                  d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <Path
                  d="M17 8L12 3L7 8"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <Path
                  d="M12 3V15"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </Svg>
              <Text
                style={{
                  color: "white",
                  fontFamily: "Gilroy-Bold",
                  fontSize: 20,
                }}
              >
                {t("postContent.choose_content")}
              </Text>
            </View>
            <Switch
              // containerStyle={{ flex: 1 }}
              value={isEnabled}
              onValueChange={toggleSwitch}
              disabled={false}
              activeText={t("postContent.free_content")}
              inActiveText={t("postContent.premium_content")}
              circleSize={48}
              activeTextStyle={{
                width: "50%",
                flex: 1,
                textAlign: "center",
                fontFamily: "Gilroy-Bold",
              }}
              inactiveTextStyle={{
                width: "50%",
                flex: 1,
                textAlign: "center",
                fontFamily: "Gilroy-Bold",
              }}
              circleBorderWidth={4}
              circleBorderActiveColor="#232839"
              circleBorderInactiveColor="#232839"
              backgroundActive={"#232839"}
              backgroundInactive={"#232839"}
              circleActiveColor={"#DB7BC6"}
              circleInActiveColor={"#DB7BC6"}
              renderInsideCircle={() => (
                <>
                  {isEnabled ? (
                    <Text style={{ color: "white", fontFamily: "Gilroy-Bold" }}>
                      {t("postContent.premium_content")}
                    </Text>
                  ) : (
                    <Text style={{ color: "white", fontFamily: "Gilroy-Bold" }}>
                      {t("postContent.free_content")}
                    </Text>
                  )}
                </>
              )}
              innerCircleStyle={{
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 24,
                width: "50%",
                flex: 1,
              }}
              outerCircleStyle={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flex: 1,
              }}
              switchLeftPx={200}
              switchRightPx={200}
              switchWidthMultiplier={(Dimensions.get("window").width - 32) / 48}
              switchBorderRadius={24}
            />
            {isEnabled ? (
              <>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    flex: 1,
                  }}
                >
                  <View
                    style={[
                      styles.paid_content,
                      {
                        padding:
                          paidConentPicker.assets != null ||
                          premiumContent != null
                            ? 0
                            : 48,
                      },
                    ]}
                  >
                    <View
                      style={{
                        display: "flex",
                        rowGap: 16,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Pressable
                        style={styles.upload_button_container}
                        onPress={async () => {
                          await pickPremiumContent();
                        }}
                      >
                        {paidConentPicker.assets != null ||
                        premiumContent != null ? (
                          <>
                            {Platform.OS === "web" ? (
                              <>
                                {isPhoto(premiumContent?.name as string) ? (
                                  <>
                                    <Image
                                      source={{
                                        uri: URL.createObjectURL(
                                          premiumContent as File
                                        ),
                                      }}
                                      style={{
                                        width: "100%",
                                        height: undefined,
                                        aspectRatio: 1,
                                        borderRadius: 16,
                                      }}
                                      resizeMode="cover"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Text style={{ color: "white" }}>
                                      {t(
                                        "postContent.videos_cannot_be_displayed"
                                      )}
                                    </Text>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {paidConentPicker.assets != null &&
                                  paidConentPicker.assets[0].type !==
                                    "video" && (
                                    <Image
                                      source={{
                                        uri:
                                          paidConentPicker.assets != null
                                            ? paidConentPicker.assets[0].uri
                                            : "",
                                      }}
                                      style={{
                                        width: "100%",
                                        height: undefined,
                                        aspectRatio: 1,
                                        borderRadius: 16,
                                      }}
                                      resizeMode="cover"
                                    />
                                  )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {/* <SvgXml xml={upload_icon} /> */}
                            <Svg
                              width={41}
                              height={41}
                              viewBox="0 0 41 41"
                              fill="none"
                            >
                              <Path
                                d="M13.8335 28.8333L20.5002 35.5L27.1668 28.8333"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <Path
                                d="M20.5 20.5V35.5"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <Path
                                d="M35.3001 30.65C36.7491 29.6311 37.8357 28.1769 38.4023 26.4986C38.9688 24.8202 38.9858 23.005 38.4508 21.3164C37.9158 19.6277 36.8566 18.1535 35.4269 17.1076C33.9973 16.0617 32.2715 15.4986 30.5001 15.5H28.4001C27.8989 13.5465 26.9609 11.7321 25.657 10.1935C24.353 8.65487 22.717 7.43211 20.8721 6.61727C19.0273 5.80242 17.0215 5.41672 15.006 5.4892C12.9905 5.56169 11.0177 6.09046 9.23605 7.03572C7.45445 7.98098 5.91051 9.31809 4.72045 10.9464C3.53038 12.5747 2.72522 14.4518 2.36557 16.4363C2.00592 18.4208 2.10115 20.461 2.6441 22.4034C3.18705 24.3458 4.16356 26.1396 5.50013 27.65"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </Svg>
                            <Text style={{ color: "white" }}>
                              {t("form.choose_photo_or_video")}
                            </Text>
                          </>
                        )}
                      </Pressable>
                    </View>
                  </View>
                </View>
              </>
            ) : (
              <>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    flex: 1,
                  }}
                >
                  <View
                    style={[
                      styles.free_content,
                      {
                        padding:
                          freeImage.assets != null || freeContent != null
                            ? 0
                            : 48,
                      },
                    ]}
                  >
                    <View
                      style={{
                        display: "flex",
                        rowGap: 16,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Pressable
                        style={styles.upload_button_container}
                        onPress={async () => {
                          await pickFreeContent();
                        }}
                      >
                        {freeImage.assets != null || freeContent != null ? (
                          <>
                            {Platform.OS === "web" ? (
                              <>
                                <Image
                                  source={{
                                    uri: URL.createObjectURL(
                                      freeContent as File
                                    ),
                                  }}
                                  style={{
                                    width: "100%",
                                    height: undefined,
                                    aspectRatio: 1,
                                    borderRadius: 16,
                                  }}
                                  resizeMode="cover"
                                />
                              </>
                            ) : (
                              <>
                                {freeImage.assets != null &&
                                  freeImage.assets[0].type !== "video" && (
                                    <Image
                                      source={{
                                        uri:
                                          freeImage.assets != null
                                            ? freeImage.assets[0].uri
                                            : "",
                                      }}
                                      style={{
                                        width: "100%",
                                        height: undefined,
                                        aspectRatio: 1,
                                        borderRadius: 16,
                                      }}
                                      resizeMode="cover"
                                    />
                                  )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Svg
                              width={41}
                              height={41}
                              viewBox="0 0 41 41"
                              fill="none"
                            >
                              <Path
                                d="M13.8335 28.8333L20.5002 35.5L27.1668 28.8333"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <Path
                                d="M20.5 20.5V35.5"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <Path
                                d="M35.3001 30.65C36.7491 29.6311 37.8357 28.1769 38.4023 26.4986C38.9688 24.8202 38.9858 23.005 38.4508 21.3164C37.9158 19.6277 36.8566 18.1535 35.4269 17.1076C33.9973 16.0617 32.2715 15.4986 30.5001 15.5H28.4001C27.8989 13.5465 26.9609 11.7321 25.657 10.1935C24.353 8.65487 22.717 7.43211 20.8721 6.61727C19.0273 5.80242 17.0215 5.41672 15.006 5.4892C12.9905 5.56169 11.0177 6.09046 9.23605 7.03572C7.45445 7.98098 5.91051 9.31809 4.72045 10.9464C3.53038 12.5747 2.72522 14.4518 2.36557 16.4363C2.00592 18.4208 2.10115 20.461 2.6441 22.4034C3.18705 24.3458 4.16356 26.1396 5.50013 27.65"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </Svg>
                            <Text style={{ color: "white" }}>
                              {t("form.choose_photo")}
                            </Text>
                          </>
                        )}
                      </Pressable>
                    </View>
                  </View>
                </View>
              </>
            )}
          </View>
        </View>
      </ScrollView>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 16,
          backgroundColor: "#151721",
        }}
      >
        <Pressable
          onPress={() => {
            if (isReadyToPost) {
              handleSubmit();
            } else {
              openSnackbar(
                "Errore",
                "Devi completare tutti i campi per poter pubblicare il contenuto",
                "error"
              );
            }
          }}
          style={[
            styles.post_button,
            { backgroundColor: isReadyToPost ? "#DB7BC6" : "#232839" },
          ]}
        >
          <Feather name="camera" size={24} color="white" />
          <Text style={{ color: "white" }}>{t("postContent.post")}</Text>
        </Pressable>
      </View>
    </>
  );
}
const styles = StyleSheet.create({
  documents_container: {
    flex: 1,
    margin: 16,
    rowGap: 16,
  },
  free_content: {
    width: "100%",
    aspectRatio: 1,
    backgroundColor: "#232839",
    borderRadius: 16,
    padding: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paid_content: {
    width: "100%",
    aspectRatio: 1,
    backgroundColor: "#232839",
    borderRadius: 16,
    padding: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  document_background_image: {
    flex: 1,
    width: "100%",
    resizeMode: "cover",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  upload_button_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    rowGap: 8,
    alignSelf: "stretch",
  },
  post_button: {
    paddingVertical: 12,
    width: "80%",
    backgroundColor: "#5F5F5F",
    flexDirection: "row",
    gap: 8,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 16,
  },
  input_container: {
    backgroundColor: "transparent",
    width: "100%",

    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // paddingTop: 8,
  },
  description_input: {
    color: "white",
    backgroundColor: "transparent",
    flex: 1,
    minHeight: Platform.OS === "ios" ? 50 : undefined,
  },
  price_input: {
    color: "white",
    backgroundColor: "transparent",
    flex: 1,
  },
  description_container: {
    borderRadius: 16,
    padding: 24,
    gap: 8,
    backgroundColor: "#232839",
  },
  price_container: {
    borderRadius: 16,
    padding: 24,
    gap: 8,
    backgroundColor: "#232839",
  },
});
