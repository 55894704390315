import {
  View,
  Text,
  StyleSheet,
  Image,
  ImageBackground,
  FlatList,
  Pressable,
  Platform,
} from "react-native";
import { useEffect, useState, useRef, useCallback } from "react";
import { useContentContext } from "../providers/ContentProvider";
import Content from "../components/common/Content";
import { FeedContentType } from "../types/content/Contents";
import { CreatorType } from "../types/creator/Creator";
import { LinearGradient } from "expo-linear-gradient";
import { Feather } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import * as Linking from "expo-linking";

import { patchFlatListProps } from "react-native-web-refresh-control";
import { useRedirectionContext } from "../providers/RedirectionProvider";
import { RefreshControl } from "react-native-gesture-handler";
import LoadingIndicator from "../components/common/LoadingIndicator";
import { APP_SERVER_URL } from "../configuration/config";
import React from "react";
if (Platform.OS === "web") patchFlatListProps({ tintColor: "#DB7BC6" });

export default function CreatorPage(props: any) {
  const { contents, getCreator, refreshContent } = useContentContext();
  const [numberOfFeedContent, setNumberOfFeedContent] = useState(5);
  const [creatorFeed, setCreatorFeed] = useState<FeedContentType[]>([]);
  const [social, setSocial] = useState<string[]>([]);
  const [refreshing, setRefresh] = useState<boolean>(false);
  const [creator, setCreator] = useState<CreatorType>();
  const { setHasParams, hasParams } = useRedirectionContext();
  const elContentLenghtRef = useRef<Map<number, number>>(
    new Map<number, number>()
  );
  const elImageLenghtRef = useRef<Map<number, number>>(
    new Map<number, number>()
  );
  const elAspectRatioRef = useRef<Map<number, number>>(
    new Map<number, number>()
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [endReached, setEndReached] = useState<boolean>(false);
  function handleLoadMore() {
    if (!endReached && !isLoading && creatorFeed.length !== 0) {
      setIsLoading(true);
      setNumberOfFeedContent(numberOfFeedContent + 5);
    }
  }
  const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));
  useEffect(() => {
    (async () => {
      if (creator == null) return;
      const contents_ = contents.filter(
        (content) => content.address == creator.address
      );
      let feedContent: FeedContentType[] = [];
      for (let i = 0; i < contents_.length && i < numberOfFeedContent; i++) {
        await wait(300);
        feedContent.push({
          content: contents_[i],
          creator: creator as CreatorType,
        });
      }
      setCreatorFeed(feedContent);
      if (feedContent.length === contents_.length) {
        setEndReached(true);
      }
      setIsLoading(false);
    })();
  }, [creator, contents, numberOfFeedContent]);

  useEffect(() => {
    (async () => {
      const address = ("0x" + props.route.params.address) as string;
      const creator = await getCreator(address);
      setCreator(creator);
    })();
  }, [props.route.params.address]);
  useEffect(() => {
    elContentLenghtRef.current.clear();
    elImageLenghtRef.current.clear();
    elAspectRatioRef.current.clear();
    setHasParams(false);
  }, []);
  async function handleReload() {
    setRefresh(true);
    await refreshContent();
    elContentLenghtRef.current.clear();
    elImageLenghtRef.current.clear();
    elAspectRatioRef.current.clear();
    setRefresh(false);
  }

  return (
    <>
      {creator != null && (
        <View style={{ flex: 1, backgroundColor: "#151721" }}>
          <View style={styles.creator_container}>
            <FlatList
              ListHeaderComponent={<CreatorDescriprion creator={creator} />}
              style={styles.creator_feed}
              data={creatorFeed}
              onEndReached={handleLoadMore}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={handleReload}
                  tintColor={"#DB7BC6"}
                />
              }
              ItemSeparatorComponent={() => (
                <View style={{ height: 32 }}></View>
              )}
              onRefresh={Platform.OS === "web" ? handleReload : undefined}
              refreshing={Platform.OS === "web" ? refreshing : undefined}
              contentContainerStyle={styles.feed_inside_container}
              renderItem={({ item, index }) => (
                <Content
                  uri={item.content.previewImage}
                  isLocked={true}
                  content={item.content}
                  creator={item.creator}
                  navigation={props.navigation}
                  setContentHeight={(height) => {
                    if (height > 0) {
                      elContentLenghtRef.current.set(index, height);
                    }
                  }}
                  setImageHeight={(height) => {
                    if (height > 0) {
                      elImageLenghtRef.current.set(index, height);
                    }
                  }}
                  setAspectRatioRef={(ratio) => {
                    if (ratio > 0) {
                      elAspectRatioRef.current.set(index, ratio);
                    }
                  }}
                  ratio={elAspectRatioRef.current.get(index)}
                  imageHeight={elImageLenghtRef.current.get(index)}
                  contentHeight={elContentLenghtRef.current.get(index)}
                />
              )}
              initialNumToRender={Platform.OS === "web" ? 4 : 0}
              maxToRenderPerBatch={4}
              updateCellsBatchingPeriod={1}
              windowSize={Platform.OS === "web" ? 8 : 2}
              viewabilityConfig={{ waitForInteraction: false }}
              keyExtractor={(item) => "creator-" + item.content.id.toString()}
              onEndReachedThreshold={0.4}
              ListFooterComponent={
                <>{!endReached && <LoadingIndicator isLoading={isLoading} />}</>
              }
            />
          </View>
        </View>
      )}
    </>
  );
}

function CreatorDescriprion({ creator }: { creator: CreatorType }) {
  const [social, setSocial] = useState<string[]>([]);
  useEffect(() => {
    setSocial([
      creator.twitter,
      creator.instagram,
      creator.telegram,
      creator.social4,
    ]);
  }, [creator]);
  return (
    <>
      <View style={styles.creator_description_container}>
        <ImageBackground
          resizeMode="cover"
          style={styles.background_image}
          source={{
            uri: `${APP_SERVER_URL}/profiles/${creator.coverImageURL}`,
          }}
          blurRadius={16}
        >
          <LinearGradient
            colors={["rgba(21,23,33,0.5)", "rgba(21,23,33,1)"]}
            style={styles.linearGradient}
          />
        </ImageBackground>
        <View
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 8,
            padding: 16,
            opacity: 1,
            zIndex: 2,
          }}
        >
          <Image
            source={{
              uri: `${APP_SERVER_URL}/profiles/${creator.profileImageURL}`,
            }}
            style={styles.profile_image}
          />
          <Text style={styles.creator_name}>{creator.name}</Text>

          <Text style={styles.creator_bio}>{creator.bio}</Text>
          <View style={styles.social_container}>
            {social.map((social, index) => {
              if (
                social == null ||
                social == undefined ||
                social === "--" ||
                social === "-" ||
                social === "" ||
                social === "undefined"
              ) {
                return <></>;
              } else if (social != null) {
                return (
                  <Pressable
                    style={{
                      backgroundColor: "#DB7BC6",
                      padding: 8,
                      borderRadius: 8,
                      alignSelf: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 8,
                    }}
                    key={social + index}
                    onPress={() => {
                      switch (index) {
                        case 0:
                          Linking.openURL("https://www.twitter.com/" + social);
                          break;

                        case 1:
                          Linking.openURL(
                            "https://www.instagram.com/" + social
                          );
                          break;

                        case 2:
                          let telegram = social.replace("@", "");
                          Linking.openURL("https://t.me/" + telegram);

                          break;

                        case 3:
                          let youtube = social.replace("@", "");
                          Linking.openURL(
                            "https://www.youtube.com/@" + youtube
                          );
                          break;
                      }
                    }}
                  >
                    {index === 0 && (
                      <>
                        <Feather name="twitter" size={18} color={"white"} />
                      </>
                    )}

                    {index === 1 && (
                      <>
                        <Entypo name="instagram" size={18} color={"white"} />
                      </>
                    )}

                    {index === 2 && (
                      <>
                        <FontAwesome5
                          name="telegram-plane"
                          size={18}
                          color={"white"}
                        />
                      </>
                    )}

                    {index === 3 && (
                      <>
                        <Feather name="youtube" size={18} color={"white"} />
                      </>
                    )}
                    <Text style={{ color: "white", fontSize: 14 }}>
                      {decodeURIComponent(social)}
                    </Text>
                  </Pressable>
                );
              }
            })}
          </View>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  feed_inside_container: {
    paddingBottom: 100,
  },
  creator_container: {
    position: "relative",
    flex: 1,
    backgroundColor: "transparent",
    height: "100%",
    display: "flex",
  },
  creator_description_container: {
    position: "relative",
    flex: 1,
    backgroundColor: "transparent",
    width: "100%",

    display: "flex",
    // paddingBottom: 32,
  },
  background_image: {
    position: "absolute",
    width: "100%",
    height: "100%",
    bottom: 0,
    left: 0,
    resizeMode: "cover",
    zIndex: 1,
  },
  profile_image: {
    height: 200,
    width: 200,
    borderRadius: 100,
    borderColor: "#DB7BC6",
    borderWidth: 8,
    alignSelf: "center",
  },
  creator_name: {
    color: "white",
    fontFamily: "Gilroy-Bold",
    fontSize: 24,
  },
  creator_bio: {
    color: "white",
    fontSize: 14,
    paddingHorizontal: 8,
  },
  creator_feed: {
    backgroundColor: "#151721",
  },
  linearGradient: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
  social_container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    gap: 16,
    flexWrap: "wrap",
    paddingVertical: 16,
  },
});
