import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resources from "../localization/translation.json";
import { createContext, useContext, useEffect, useState } from "react";
import { TranslationProviderType } from "../types/providers/TranslationProvider";
import AsyncStorageStatic from "@react-native-async-storage/async-storage";

const TranslationContext = createContext<TranslationProviderType>(
  {} as TranslationProviderType
);
export const TranslationProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [language, setLanguage] = useState("en");
  useEffect(() => {
    (async () => {
      const language = await AsyncStorageStatic.getItem("language");
      if (language != null) {
        setLanguage(language);
        await i18n.changeLanguage(language);
      } else {
        await changeLanguage("it");
      }
    })();
  }, []);
  const changeLanguage = async (lang: string): Promise<void> => {
    setLanguage(lang);
    await AsyncStorageStatic.setItem("language", lang);
    await i18n.changeLanguage(lang);
  };

  useEffect(() => {
    (async () => {
      await i18n.use(initReactI18next).init({
        compatibilityJSON: "v3",
        resources,
        lng: "it",
        interpolation: {
          escapeValue: false,
        },
      });
    })();
  }, []);

  const translationProviderData: TranslationProviderType = {
    language,
    changeLanguage,
    i18n,
  };

  return (
    <TranslationContext.Provider value={translationProviderData}>
      {children}
    </TranslationContext.Provider>
  );
};
export const useTranslationProvider = () => useContext(TranslationContext);
