import { createContext, useContext, useEffect, useState } from "react";
import "react-native-get-random-values";

import "@ethersproject/shims";
import { BigNumber, ethers } from "ethers";
import { BlockchainContext } from "../types/providers/BlockchainProvider";
import ABI from "../contract/ABI.json";
import { useWalletContext } from "./WalletProvider";
import { CategoryType, ContentType } from "../types/content/Contents";
import { isPhoto } from "../utils/imageController";
import { CreatorType } from "../types/creator/Creator";
import {
  RPC_URL,
  NETLI_OF_CONTRACT_ADDRESS,
  TEST_CREATORS,
} from "../configuration/config";

const BlockchainContextImpl = createContext<BlockchainContext>(
  {} as BlockchainContext
);
export const BlockchainProvider = ({ children }: { children: JSX.Element }) => {
  const { walletInfo } = useWalletContext();
  const [balance, setBalance] = useState<number>(0);
  const [activeBalanceListener, setActiveBalanceListener] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (walletInfo == null) return;
      const balance = await getMaticBalance();
      setBalance(balance);
    })();
  }, [walletInfo]);

  useEffect(() => {
    if (walletInfo != null && !activeBalanceListener) {
      const provider = getProvider();
      let lastBalance = ethers.constants.Zero;
      provider.on("block", () => {
        provider.getBalance(walletInfo.address).then((balance) => {
          if (!balance.eq(lastBalance)) {
            lastBalance = balance;
            const balanceInEth = ethers.utils.formatEther(balance);
            setBalance(Number(balanceInEth));
          }
        });
      });
      setActiveBalanceListener(true);
    }
  }, [walletInfo]);
  const getProvider = () => {
    const provider = new ethers.providers.JsonRpcProvider(RPC_URL);

    return provider;
  };

  const getSigner = () => {
    if (walletInfo == null) return;
    let signer;
    signer = new ethers.Wallet(walletInfo.privateKey, getProvider());
    return signer;
  };
  useEffect(() => {
    if (walletInfo != null) {
      const signer = getSigner();
      if (signer != null) {
        //test message signing
        (async () => {
          const signerWallet = ethers.Wallet.fromMnemonic(walletInfo.mnemonic);
          const signedMessage = await signer.signMessage("authenticate");
          // console.log(signedMessage);
          const publicKey = signerWallet.publicKey;
          const decodedMessage = ethers.utils.verifyMessage(
            "authenticate",
            signedMessage
          );
          // console.log(decodedMessage);
        })();
      }
    }
  }, [walletInfo]);
  const getContent = async (): Promise<ContentType[]> => {
    const signer = getSigner();
    const contract = getContract(signer);
    let contents: ContentType[] = [];
    try {
      const content = await contract["retrieveContents()"]();
      for (let i = 0; i < content.length; i++) {
        // const isImage = isPhoto(content[i][5]);
        // for testing
        // let previewImage =
        // 	Number(ethers.utils.formatEther(content[i][1])) === 0.005
        // 		? "3557864_1156b01b9.jpeg"
        // 		: "5923429_c77ef81621.jpeg";
        // let paidContent =
        // 	Number(ethers.utils.formatEther(content[i][1])) === 0.005
        // 		? "7147773_1156b01b10.mp4"
        // 		: "5987422_c77ef81622.jpeg";
        // // console.log(Number(content[i][2]));
        // if (Number(content[i][2]) < 1688567767) {
        // 	contents.push({
        // 		address: content[i][0],
        // 		matic: Number(ethers.utils.formatEther(content[i][1])),
        // 		timestamp: Number(content[i][2]),
        // 		title: content[i][3],
        // 		previewImage: previewImage,
        // 		paidContent: paidContent,
        // 		category: Number(content[i][6]) - 1,
        // 		id: Number(content[i][7]),
        // 		type:
        // 			Number(ethers.utils.formatEther(content[i][1])) === 0.005
        // 				? "video"
        // 				: "image",
        // 	} as ContentType);
        // } else if (Number(content[i][2]) > 1689585855) {
        const isImage = isPhoto(content[i][5]);
        let premiumContent = String(content[i][5]);
        if (premiumContent.split(".")[1] === "mov") {
          premiumContent = premiumContent.split(".")[0] + ".mp4";
        }
        contents.push({
          address: content[i][0],
          matic: Number(ethers.utils.formatEther(content[i][1])),
          timestamp: Number(content[i][2]),
          title: content[i][3],
          previewImage: content[i][4],
          paidContent: premiumContent,
          category: Number(content[i][6]),
          id: Number(content[i][7]),
          type: isImage ? "image" : "video",
        } as ContentType);
      }
      // }
    } catch (err) {
      console.log(err);
    }
    return contents.reverse();
  };

  const getContract = (signer: any) => {
    let contract;
    contract = new ethers.Contract(NETLI_OF_CONTRACT_ADDRESS, ABI, signer);

    return contract;
  };

  const getWeiFromMatic = (matic: number) => {
    let wei: BigNumber = ethers.utils.parseEther(matic.toString());

    return wei.toString();
  };

  const getCreators = async (): Promise<CreatorType[]> => {
    const signer = getSigner();
    const contract = getContract(signer);
    const creators_ = await contract["retrieveCreators()"]();
    const creators: CreatorType[] = [];
    for (let i = 0; i < creators_.length; i++) {
      if (!TEST_CREATORS.includes(creators_[i][1]))
        creators.push({
          address: creators_[i][0],
          name: creators_[i][1],
          bio: creators_[i][2],
          profileImageURL: creators_[i][3],
          coverImageURL: creators_[i][4],
          instagram: decodeURIComponent(creators_[i][5]),
          telegram: decodeURIComponent(creators_[i][6]),
          twitter: decodeURIComponent(creators_[i][7]),
          social4: decodeURIComponent(creators_[i][8]),
        });
    }
    return creators;
  };
  const getCreatorByAddress = async (
    creatorAddress: string
  ): Promise<CreatorType> => {
    const signer = getSigner();
    const contract = getContract(signer);
    const creator_ = await contract["retrieveCreatorByAddress(address)"](
      creatorAddress
    );
    const creator: CreatorType = {
      address: creator_[0],
      name: creator_[1],
      bio: creator_[2],
      profileImageURL: creator_[3],
      coverImageURL: creator_[4],
      instagram: creator_[5],
      telegram: creator_[6],
      twitter: creator_[7],
      social4: creator_[8],
    };
    return creator;
  };
  const getMaticBalance = async (): Promise<number> => {
    let balance = 0;
    if (walletInfo == null) {
      //TODO handle catching of this error
      throw new Error("Wallet is not connected");
    }
    try {
      const provider = getProvider();
      const balanceResponse = await provider.getBalance(walletInfo.address);
      balance = Number(ethers.utils.formatEther(balanceResponse));
    } catch (err) {
      console.error(err);
    }

    return balance;
  };
  const getCategories = async (): Promise<CategoryType[]> => {
    let categories: CategoryType[] = [];
    try {
      const signer = getSigner();
      const contract = getContract(signer);
      const categories_ = await contract["retrieveCategories()"]();
      for (let i = 0; i < categories_.length; i++) {
        categories.push({
          id: Number(categories_[i][0]),
          name: categories_[i][1],
          description: categories_[i][2],
        });
      }
    } catch (err) {
      console.error(err);
    }
    return categories;
  };
  const getPurchaseContentByAddress = async (
    address: string
  ): Promise<number[]> => {
    let purchasedContent: number[] = [];
    try {
      const signer = getSigner();
      const contract = getContract(signer);
      const content = await contract["retrievePurchaseByAddress(address)"](
        address
      );
      for (let i = 0; i < content.length; i++) {
        purchasedContent.push(Number(content[i][0]));
      }
    } catch (err) {
      console.error(err);
    }
    return purchasedContent;
  };
  const isAddressCreator = async (address: string) => {
    let isCreator = false;
    try {
      const signer = getSigner();
      const contract = getContract(signer);
      const result = await contract["_isCreator(address)"](address);
      isCreator = result;
    } catch (err) {
      console.log(err);
    }
    return isCreator;
  };
  const getNumberOfContentOfCreator = async (address: string) => {
    let numberOfContent = 0;
    try {
      const signer = getSigner();
      const contract = getContract(signer);
      const result = await contract["_howManyPerCreator(address)"](address);
      numberOfContent = Number(result);
    } catch (err) {
      console.log(err);
    }
    return numberOfContent;
  };
  //Write calls
  const buyContent = async (contentId: string, maticAmount: number) => {
    try {
      const signer = getSigner();
      const contract = getContract(signer);
      const price = ethers.utils.parseUnits(maticAmount.toString(), "ether");
      const gasPrice = await signer?.getGasPrice();

      if (gasPrice != null) {
        const finalGasPrice = Number(gasPrice);
      }
      const functionGasFees = await contract.estimateGas["purchase(uint256)"](
        contentId,
        {
          value: price,
        }
      );
      const tx = await contract["purchase(uint256)"](contentId, {
        value: price,
        gasPrice: Number(gasPrice),
        gasLimit: functionGasFees,
      });
      const trx = await tx.wait();
    } catch (err) {
      //TODO handle catching of this error
      const error = err as Error;
      console.log(err);
      if (error.message.includes("You already own this content!")) {
        throw new Error("You already own this content!");
      }

      throw new Error("Something went wrong");
    }
  };
  const modifyProfile = async (creator: CreatorType) => {
    try {
      const signer = getSigner();
      const contract = getContract(signer);
      const gasPrice = await signer?.getGasPrice();
      if (gasPrice != null) {
        const finalGasPrice = Number(gasPrice);
      } else {
        throw new Error("could not determine gas price");
      }
      const functionGasFees = await contract.estimateGas[
        "addCreator(address,string,string,string,string,string,string,string,string)"
      ](
        creator.address,
        creator.name,
        creator.bio,
        creator.profileImageURL,
        creator.coverImageURL,
        creator.instagram,
        creator.telegram,
        creator.twitter,
        creator.social4
      );

      const tx = await contract[
        "addCreator(address,string,string,string,string,string,string,string,string)"
      ](
        creator.address,
        creator.name,
        creator.bio,
        creator.profileImageURL,
        creator.coverImageURL,
        creator.instagram,
        creator.telegram,
        creator.twitter,
        creator.social4,
        {
          gasPrice: gasPrice,
          gasLimit: functionGasFees,
        }
      );
      const trx = await tx.wait();
    } catch (err) {
      console.log(err);
    }
  };
  const postContent = async (
    price: string,
    description: string,
    category: number,
    previewUrl: string,
    paidUrl: string
  ) => {
    try {
      const signer = getSigner();
      const contract = getContract(signer);
      const gasPrice = await signer?.getGasPrice();
      if (gasPrice != null) {
        const finalGasPrice = Number(gasPrice);
      } else {
        throw new Error("could not determine gas price");
      }
      const functionGasFees = await contract.estimateGas[
        "addContent(uint256,string,string,string,uint256)"
      ](price, description, previewUrl, paidUrl, category);
      const tx = await contract[
        "addContent(uint256,string,string,string,uint256)"
      ](price, description, previewUrl, paidUrl, category, {
        gasPrice: gasPrice,
        gasLimit: functionGasFees,
      });
      const trx = await tx.wait();
    } catch (err) {
      console.log(err);
    }
  };
  const isAddressValid = (address: string): boolean => {
    return ethers.utils.isAddress(address);
  };
  const sendMatic = async (
    fromAddress: string,
    toAddress: string,
    amount: number
  ): Promise<void> => {
    const signer = getSigner();
    const contract = getContract(signer);
    const gasPrice = await signer?.getGasPrice();
    if (gasPrice != null) {
      const finalGasPrice = Number(gasPrice);
    } else {
      throw new Error("could not determine gas price");
    }

    const gasFees = await signer?.estimateGas({
      from: fromAddress,
      to: toAddress,
      data: "0x",
      value: ethers.utils.parseEther(amount.toString()),
    });

    const transactionParameters = {
      from: fromAddress,
      to: toAddress,
      data: "0x",
      value: ethers.utils.parseEther(amount.toString()),
      gasLimit: gasFees,
      gasPrice: ethers.utils.hexlify(gasPrice),
    };
    const tx = await signer?.sendTransaction(transactionParameters);
  };

  const values: BlockchainContext = {
    balance,
    getContent,
    getCreators,
    getCreatorByAddress,
    getMaticBalance,
    getCategories,
    buyContent,
    getPurchaseContentByAddress,
    getNumberOfContentOfCreator,
    isAddressCreator,
    modifyProfile,
    postContent,
    getWeiFromMatic,
    isAddressValid,
    sendMatic,
  };

  return (
    <BlockchainContextImpl.Provider value={values}>
      {children}
    </BlockchainContextImpl.Provider>
  );
};

export const useBlockchainContext = () => useContext(BlockchainContextImpl);
