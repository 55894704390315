import { useState, useEffect } from "react";
import {
  View,
  Modal,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Pressable,
} from "react-native";

import { SvgXml } from "react-native-svg";
import maticLogo from "../../assets/polygon_matic_logo.svg";
import { useBlockchainContext } from "../../providers/BlockchainProvider";
import BlockchainOperationLoading from "../loading/BlockchainOperationLoading";
import { useLoadingContext } from "../../providers/LoadingProvider";
import { useSnackbar } from "../../providers/SnackBarProvider";
import { useTranslation } from "react-i18next";
import PurchaseModal from "./PurchaseModal";
import { useWalletContext } from "../../providers/WalletProvider";
export default function PurchaseContentModal({
  isOpen,
  matic,
  id,
  handleBack,
  navigation,
}: {
  isOpen: boolean;
  matic: number;
  id: string;
  handleBack: () => void;
  navigation: any;
}) {
  const { buyContent, balance } = useBlockchainContext();
  const { setIsLoading } = useLoadingContext();
  const { openSnackbar } = useSnackbar();
  const [message, setMessage] = useState<string>("");
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] =
    useState<boolean>(false);
  const { walletInfo } = useWalletContext();

  const handleBackPurchaseModal = () => {
    setIsPurchaseModalOpen(false);
  };

  const { t } = useTranslation();
  async function handleBuy() {
    try {
      handleBack();
      if (balance < matic) throw new Error("Insufficent balance");
      setMessage("Buying...");
      setIsLoading(true);
      await buyContent(id, matic);
      setIsLoading(false);
      openSnackbar("Success", "Contenuto acquistato correttamente", "success");
    } catch (err) {
      const error = err as Error;
      openSnackbar("Error", error.message, "error");
      if (error.message === "Insufficent balance") {
        // navigation.navigate("Profile");
        setIsPurchaseModalOpen(true);
      }
      console.log(err);
      setIsError(true);
      setIsLoading(false);
      setMessage(error.message);
    }
  }
  function handleCloseLoading() {
    setIsLoading(false);
    setIsError(false);
    setMessage("");
  }
  return (
    <>
      <PurchaseModal
        isOpen={isPurchaseModalOpen}
        handleBack={handleBackPurchaseModal}
        walletAddress={walletInfo?.address!}
      />
      <Modal
        animationType="fade"
        transparent={true}
        visible={isOpen}
        statusBarTranslucent={false}
        onRequestClose={() => {
          handleBack();
        }}
      >
        <TouchableWithoutFeedback
          style={styles.centeredView}
          onPress={() => handleBack()}
        >
          <View style={styles.centeredView}>
            <Pressable style={styles.modalView}>
              <View style={styles.purchase_information}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    alignItems: "center",
                  }}
                >
                  <Text style={{ color: "white", fontSize: 12 }}>
                    {t("common.price").toLocaleUpperCase()}:
                  </Text>
                  <Text
                    style={{
                      color: "white",
                      fontSize: 16,
                      fontFamily: "Gilroy-Bold",
                    }}
                  >
                    {" "}
                    {matic} POL
                  </Text>
                </View>
                <TouchableOpacity
                  style={styles.buy_button}
                  activeOpacity={0.8}
                  onPress={() => handleBuy()}
                >
                  <Text
                    style={{
                      fontFamily: "Gilroy-Bold",
                      color: "white",
                      fontSize: 18,
                    }}
                  >
                    {t("common.buy")}
                  </Text>
                </TouchableOpacity>
              </View>
            </Pressable>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </>
  );
}
const styles = StyleSheet.create({
  centeredView: {
    position: "relative",
    height: "100%",
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000a",
  },
  modalView: {
    display: "flex",
    gap: 16,
    width: "80%",
    padding: 16,
    backgroundColor: "#151721",
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  purchase_page_container: {
    padding: 16,
    flex: 1,
    height: "100%",
    display: "flex",
    backgroundColor: "#151721",
  },
  purchase_information: {
    display: "flex",
    flexDirection: "column",
    rowGap: 16,
    justifyContent: "center",
    padding: 16,
  },
  buy_button: {
    marginTop: 8,
    backgroundColor: "#DB7BC6",
    borderRadius: 16,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
});
