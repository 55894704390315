import {
  StyleSheet,
  Text,
  View,
  Pressable,
  Platform,
  ScrollView,
  Image,
} from "react-native";
import { useCallback, useEffect, useState } from "react";

import { useAuthenticationContext } from "../providers/AuthenticationProvider";
import { SafeAreaView } from "react-native-safe-area-context";
import { useWalletContext } from "../providers/WalletProvider";
import { useBlockchainContext } from "../providers/BlockchainProvider";
import { useUserContext } from "../providers/UserProvider";
import { useTranslation } from "react-i18next";
import { useTranslationProvider } from "../providers/TranslationProvider";
import DropDownPicker from "react-native-dropdown-picker";
import { AntDesign, Feather, FontAwesome } from "@expo/vector-icons";
import matic_fill_logo from "../assets/polygon_matic_fill_logo.png";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { G, Path, Svg, SvgXml } from "react-native-svg";
import { LinearGradient } from "expo-linear-gradient";
import * as Clipboard from "expo-clipboard";
import PurchaseModal from "../components/modal/PurchaseModal";
import SendMaticModal from "../components/modal/SendMaticModal";
import { useSnackbar } from "../providers/SnackBarProvider";
import { useContentContext } from "../providers/ContentProvider";

import { RefreshControl as RefreshControlWeb } from "react-native-web-refresh-control";
import { useLoadingContext } from "../providers/LoadingProvider";
import React from "react";
import { DAPP_URL } from "../configuration/config";

export default function Profile({ navigation }: { navigation: any }) {
  const { setIsAuthenticated } = useAuthenticationContext();
  const { userInformation } = useUserContext();

  const { t, i18n } = useTranslation();
  const { getWalletAddress, setWalletInfo } = useWalletContext();
  const { getCreator } = useContentContext();
  const { balance, sendMatic } = useBlockchainContext();
  const [walletAddress, setWalletAddress] = useState("");

  const { isCreator, handleIsCreator } = useUserContext();
  const { language, changeLanguage } = useTranslationProvider();
  const [open, setOpen] = useState(false);
  const [openSendMaticModal, setOpenSendMaticModal] = useState(false);
  const [languageValue, setLanguageValue] = useState(language);
  const { openSnackbar } = useSnackbar();
  const { setIsLoading } = useLoadingContext();
  const [purchaseModalOpen, setPurchaseModalOpen] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState(false);
  function handleBackSendMaticModal() {
    setOpenSendMaticModal(false);
  }
  async function onRefresh() {
    setRefreshing(true);
    try {
      await handleIsCreator(walletAddress);
      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
    }
  }
  async function handleGetWalletAddress() {
    try {
      const walletAddress = await getWalletAddress();
      if (walletAddress != null) {
        setWalletAddress(walletAddress);
      }
    } catch (err) {
      const error = err as Error;
      let errorMessage = t("errors.something_went_wrong");
      if (error.message === "Unable to get the wallet address")
        errorMessage = t("errors.unable_to_get_the_wallet_information");
      openSnackbar(t("common.error"), errorMessage, "error");
    }
  }

  const languages = [
    { value: "en", label: "EN" },
    { value: "it", label: "IT" },
  ];
  async function handleChangeLanguage(language: string) {
    await changeLanguage(language);
  }
  useEffect(() => {
    handleChangeLanguage(languageValue);
  }, [languageValue]);
  useEffect(() => {
    handleGetWalletAddress();
  }, []);
  async function copyToClipboard() {
    try {
      await Clipboard.setStringAsync(walletAddress);
      openSnackbar(
        t("common.success"),
        t("messanges.saved_in_clipboard"),
        "success"
      );
    } catch (err) {
      console.error(err);
      openSnackbar(t("common.error"), t("errors.saved_in_clipboard"), "error");
    }
  }
  async function copyToLink() {
    try {
      const link =
        `${DAPP_URL}/records/user?name=` +
        userInformation.name +
        "&address=" +
        walletAddress.slice(2);
      await Clipboard.setStringAsync(link);
      openSnackbar(
        t("common.success"),
        t("messanges.saved_in_clipboard"),
        "success"
      );
    } catch (err) {
      console.error(err);
      openSnackbar(t("common.error"), t("errors.saved_in_clipboard"), "error");
    }
  }
  function handleBackPurchaseModal() {
    setPurchaseModalOpen(false);
  }

  async function handleSendMatic(address: string, amount: number) {
    try {
      setIsLoading(true);
      await sendMatic(walletAddress, address, amount);
      openSnackbar(t("common.success"), t("sendMatic.success"), "success");
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      openSnackbar(t("common.error"), t("sendMatic.error"), "error");

      setIsLoading(false);
    }
  }

  return (
    <>
      <PurchaseModal
        isOpen={purchaseModalOpen}
        handleBack={handleBackPurchaseModal}
        walletAddress={walletAddress}
      />
      <SendMaticModal
        isOpen={openSendMaticModal}
        handleBack={handleBackSendMaticModal}
        handleSendMatic={handleSendMatic}
      />
      <SafeAreaView style={{ backgroundColor: "#151721", flex: 1 }}>
        <ScrollView
          contentContainerStyle={styles.profile_container}
          refreshControl={
            <RefreshControlWeb
              refreshing={refreshing}
              onRefresh={onRefresh}
              tintColor={"#DB7BC6"}
            ></RefreshControlWeb>
          }
        >
          <View style={styles.profile_header}>
            <Text style={styles.profile_header_text}>
              {t("profile.your_account")}
            </Text>
            <View>
              <DropDownPicker
                open={open}
                value={languageValue}
                items={languages}
                setOpen={setOpen}
                setValue={setLanguageValue}
                theme="DARK"
                placeholder="Language"
                searchable={false}
                style={{
                  backgroundColor: "transparent",
                  borderWidth: 0,
                  justifyContent: "flex-start",
                  padding: 0,
                  margin: 0,
                }}
                labelStyle={{ flex: 0 }}
                containerStyle={{ justifyContent: "center" }}
                textStyle={{ fontSize: 16 }}
                iconContainerStyle={{ display: "none" }}
                dropDownContainerStyle={{
                  backgroundColor: "transparent",
                  alignSelf: "flex-end",
                  borderWidth: 0,
                  borderTopWidth: 1,
                }}
                tickIconStyle={{ display: "none" }}
              />
            </View>
          </View>

          <View style={styles.balance_container}>
            <Text style={{ fontSize: 16, fontWeight: "500", color: "white" }}>
              {t("profile.total_balance")}
            </Text>
            <Text style={{ marginBottom: 16 }}>
              <Text style={[styles.balance_text, { lineHeight: 48 }]}>
                {balance.toFixed(2)}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "white",
                  fontWeight: "400",
                  textAlignVertical: "bottom",
                }}
              >
                {"  "}POL
              </Text>
            </Text>
            <View
              style={{ flexDirection: "row", gap: 16, alignItems: "center" }}
            >
              <Pressable
                onPress={async () => {
                  await copyToClipboard();
                }}
                style={{
                  flexDirection: "row",
                  gap: 8,
                  alignItems: "center",
                  borderRadius: 32,
                  backgroundColor: "rgba(219, 123, 198, 0.4)",
                  paddingHorizontal: 16,
                  paddingVertical: 8,
                  alignSelf: "flex-start",
                }}
              >
                <Feather name="copy" size={20} color="white" />
                <Text style={{ color: "white", fontSize: 12 }}>
                  {walletAddress.substring(0, 4).toLocaleUpperCase()}...
                  {walletAddress
                    .substring(walletAddress.length - 4)
                    .toLocaleUpperCase()}
                </Text>
              </Pressable>
              {isCreator && (
                <Pressable
                  onPress={async () => {
                    await copyToLink();
                  }}
                  style={{
                    flexDirection: "row",
                    gap: 8,
                    alignItems: "center",
                    borderRadius: 32,
                    backgroundColor: "rgba(219, 123, 198, 0.4)",
                    paddingHorizontal: 16,
                    paddingVertical: 8,
                    alignSelf: "flex-start",
                  }}
                >
                  <Feather name="copy" size={20} color="white" />
                  <Text style={{ color: "white", fontSize: 12 }}>
                    {t("profile.copy_link")}
                  </Text>
                </Pressable>
              )}
            </View>
          </View>
          <View style={styles.matic_operation_container}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Image
                source={matic_fill_logo}
                style={{ width: 40, height: 40 }}
              />
              <View
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{ fontSize: 16, fontWeight: "700", color: "white" }}
                >
                  Polygon
                </Text>
                <Text
                  style={{ fontSize: 12, fontWeight: "500", color: "white" }}
                >
                  POL
                </Text>
              </View>
            </View>
            <View style={styles.matic_operation_buttons_container}>
              <Pressable
                onPress={() => {
                  // navigation.navigate("Receive");
                  setOpenSendMaticModal(true);
                }}
                style={[
                  styles.matic_operaiton_button,
                  {
                    backgroundColor: "#232839",
                    borderWidth: 2,
                    borderColor: "#DB7BC6",
                    padding: 14,
                    elevation: 16,
                    shadowColor: "rgba(219, 123, 198, 0.9)",
                    shadowRadius: Platform.OS === "web" ? 8 : 24,
                    shadowOpacity: Platform.OS === "web" ? 0.15 : undefined,

                    shadowOffset: { width: 0, height: 8 },
                  },
                ]}
              >
                <Text style={styles.button_text}>
                  {t("profile.send_matic")}
                </Text>
              </Pressable>

              <LinearGradient
                colors={["#AB3191", "#DB7BC6", "#A72A8B"]}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                style={{
                  width: "100%",
                  borderRadius: 16,
                  elevation: 16,
                  shadowRadius: Platform.OS === "web" ? 8 : 24,
                  shadowOpacity: Platform.OS === "web" ? 0.4 : undefined,
                  shadowColor: "rgba(219, 123, 198, 0.9)",
                  shadowOffset: { width: 0, height: 8 },
                }}
              >
                <Pressable
                  onPress={() => {
                    setPurchaseModalOpen(true);
                  }}
                  style={[
                    styles.matic_operaiton_button,
                    {
                      backgroundColor: "transparent",
                    },
                  ]}
                >
                  <Text style={styles.button_text}>
                    {t("profile.purchase_matic")}
                  </Text>
                </Pressable>
              </LinearGradient>
            </View>
          </View>
          <View style={styles.buttons_container}>
            <Pressable
              onPress={() => {
                navigation.navigate("Wallet Information");
              }}
              style={styles.button_container}
            >
              <View style={styles.button_text_container}>
                {/* <SvgXml xml={wallet_icon} /> */}
                <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
                  <Path
                    d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <Path
                    d="M1 10H23"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </Svg>
                <Text style={styles.button_text}>
                  {t("profile.wallet_information")}
                </Text>
              </View>
              <AntDesign name="right" size={16} color="white" />
            </Pressable>

            {isCreator ? (
              <>
                <Pressable
                  onPress={() => {
                    navigation.navigate("Post Content");
                  }}
                  style={styles.button_container}
                >
                  <View style={styles.button_text_container}>
                    {/* <SvgXml xml={post_content_icon} /> */}
                    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
                      <Path
                        d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <Path
                        d="M12 8V16"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <Path
                        d="M8 12H16"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </Svg>
                    <Text style={styles.button_text}>
                      {t("profile.post_content")}
                    </Text>
                  </View>
                  <AntDesign name="right" size={16} color="white" />
                </Pressable>
                <Pressable
                  onPress={() => {
                    navigation.navigate("Modify Profile");
                  }}
                  style={styles.button_container}
                >
                  <View style={styles.button_text_container}>
                    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
                      <Path
                        d="M14.6999 6.29998C14.5166 6.48691 14.414 6.73823 14.414 6.99998C14.414 7.26173 14.5166 7.51305 14.6999 7.69998L16.2999 9.29998C16.4868 9.48321 16.7381 9.58584 16.9999 9.58584C17.2616 9.58584 17.5129 9.48321 17.6999 9.29998L21.4699 5.52998C21.9727 6.64117 22.1249 7.87921 21.9063 9.07913C21.6877 10.279 21.1086 11.3838 20.2461 12.2463C19.3837 13.1087 18.2789 13.6878 17.079 13.9064C15.8791 14.1251 14.641 13.9728 13.5299 13.47L6.61986 20.38C6.22203 20.7778 5.68246 21.0013 5.11986 21.0013C4.55725 21.0013 4.01768 20.7778 3.61986 20.38C3.22203 19.9822 2.99854 19.4426 2.99854 18.88C2.99854 18.3174 3.22203 17.7778 3.61986 17.38L10.5299 10.47C10.027 9.35879 9.87477 8.12075 10.0934 6.92083C10.312 5.72092 10.8911 4.61614 11.7536 3.7537C12.616 2.89127 13.7208 2.31215 14.9207 2.09352C16.1206 1.8749 17.3587 2.02714 18.4699 2.52998L14.7099 6.28998L14.6999 6.29998Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </Svg>
                    <Text style={styles.button_text}>
                      {t("profile.modify_profile")}
                    </Text>
                  </View>
                  <AntDesign name="right" size={16} color="white" />
                </Pressable>
                <Pressable
                  onPress={async () => {
                    const creator = await getCreator(walletAddress);
                    navigation.navigate("Creator", {
                      address: creator.address.slice(2),
                      name: creator.name,
                    });
                  }}
                  style={styles.button_container}
                >
                  <View style={styles.button_text_container}>
                    <MaterialCommunityIcons
                      name="post"
                      size={24}
                      color="white"
                    />

                    <Text style={styles.button_text}>
                      {t("profile.your_contents")}
                    </Text>
                  </View>
                  <AntDesign name="right" size={16} color="white" />
                </Pressable>
              </>
            ) : (
              <>
                <Pressable
                  onPress={() => {
                    navigation.navigate("Become A Creator");
                  }}
                  style={styles.button_container}
                >
                  <View style={styles.button_text_container}>
                    {/* <SvgXml xml={post_content_icon} /> */}
                    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
                      <Path
                        d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <Path
                        d="M12 8V16"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <Path
                        d="M8 12H16"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </Svg>
                    <Text style={styles.button_text}>
                      {t("profile.become_a_creator")}
                    </Text>
                  </View>
                  <AntDesign name="right" size={16} color="white" />
                </Pressable>
              </>
            )}
            <Pressable
              onPress={() => {
                setIsAuthenticated(false);
                setWalletInfo(null);
                navigation.navigate("Authentication");
              }}
              style={styles.button_container}
            >
              <View style={styles.button_text_container}>
                {/* <SvgXml xml={log_out_icon} /> */}
                <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
                  <Path
                    d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <Path
                    d="M16 17L21 12L16 7"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <Path
                    d="M21 12H9"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </Svg>
                <Text style={styles.button_text}>Log out</Text>
              </View>
              <AntDesign name="right" size={16} color="white" />
            </Pressable>
            <Pressable
              onPress={() => {
                navigation.navigate("DeleteAccount");
              }}
              style={styles.button_container}
            >
              <View style={styles.button_text_container}>
                <View style={{ height: 24, width: 24 }}>
                  <FontAwesome name="trash-o" size={24} color="white" />
                </View>
                <Text style={styles.button_text}>
                  {t("deleteAccount.button")}
                </Text>
              </View>
              <AntDesign name="right" size={16} color="white" />
            </Pressable>
          </View>
        </ScrollView>
      </SafeAreaView>
    </>
  );
}
const styles = StyleSheet.create({
  profile_container: {
    marginHorizontal: 16,
    backgroundColor: "#151721",
    display: "flex",
    flexDirection: "column",
    rowGap: 16,
    minHeight: "100%",
  },
  profile_header: {
    zIndex: 9999,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  profile_header_text: {
    color: "white",
    fontSize: 24,
    fontWeight: "700",
  },

  balance_container: {
    borderRadius: 16,
    backgroundColor: "#232839",
    padding: 24,
  },
  balance_text: {
    fontSize: 48,
    color: "white",
    fontWeight: "800",
  },
  button_container: {
    backgroundColor: "#232839",
    minWidth: "50%",
    borderRadius: 16,
    padding: 24,
    zIndex: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  matic_operation_container: {
    padding: 24,
    borderRadius: 16,
    backgroundColor: "#232839",
    display: "flex",
    gap: 24,
  },
  matic_operation_buttons_container: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    justifyContent: "center",
  },
  matic_operaiton_button: {
    backgroundColor: "#DB7BC6",
    width: "100%",
    borderRadius: 16,
    padding: 16,

    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    textAlign: "center",
    zIndex: 0,
  },
  button_text_container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  button_text: {
    fontSize: 16,
    color: "white",
    fontWeight: "bold",
    fontFamily: "Gilroy-Bold",
  },
  buttons_container: {
    flex: 1,
    display: "flex",
    rowGap: 16,
    marginBottom: 100,
  },
});
