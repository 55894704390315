import { useState, useEffect } from "react";
import {
  StyleSheet,
  Platform,
  View,
  Text,
  TextInput,
  Pressable,
  Dimensions,
} from "react-native";
import Checkbox from "expo-checkbox";
import Pin from "../../pages/Pin";
import { useWalletContext } from "../../providers/WalletProvider";
import { useLoadingContext } from "../../providers/LoadingProvider";
import { useSnackbar } from "../../providers/SnackBarProvider";
import { useAuthenticationContext } from "../../providers/AuthenticationProvider";
import * as Clipboard from "expo-clipboard";
import { ScrollView } from "react-native";
import { useTranslation } from "react-i18next";
import * as LocalAuthentication from "expo-local-authentication";

export default function WalletFromMnemonic({
  navigation,
}: {
  navigation: any;
}) {
  const [mnemonicSubmitted, setMnemonicSubmitted] = useState<boolean>(false);
  const [pin, setPin] = useState<number[]>([]);
  const [arrayMnemonic, setArrayMnemonic] = useState<string[]>(
    Array.from({ length: 12 }, () => "")
  );

  const { setIsLoading } = useLoadingContext();
  const { openSnackbar } = useSnackbar();
  const { loadWalletFromMnemonic } = useWalletContext();
  const { setHasWallet } = useAuthenticationContext();
  const { t } = useTranslation();
  async function handleLoadWalletFromMnemonic() {
    try {
      setIsLoading(true);
      const useBiometrics = await LocalAuthentication.isEnrolledAsync();

      await loadWalletFromMnemonic(
        arrayMnemonic.join(" "),
        pin.join(""),
        useBiometrics
      );
      setHasWallet(true);
      setIsLoading(false);
      navigation.navigate("Home");
    } catch (err) {
      setIsLoading(false);
      const error = err as Error;
      openSnackbar(t("errors.something_went_wrong"), error.message, "error");
    }
  }
  function handleUpdate(word: string, index: number) {
    if (word.split(" ").length === 12) {
      const mnemonicArray = word.split(" ");
      setArrayMnemonic(mnemonicArray);
      return;
    }

    let newArray = [...arrayMnemonic];
    newArray[index] = word.trim();
    setArrayMnemonic(newArray);
  }
  async function handlePasteMnemonic() {
    const mnemonicFromClipboard = await Clipboard.getStringAsync();
    const mnemonicArray = mnemonicFromClipboard.split(" ");
    if (mnemonicArray.length === 12) {
      setArrayMnemonic(mnemonicArray);
    } else {
      openSnackbar(
        t("errors.invalid_mnemonic"),
        t("errors.mnemonic_must_have_12_words"),
        "error"
      );
    }
  }
  function handleSumbit() {
    try {
      for (let i = 0; i < arrayMnemonic.length; i++) {
        if (arrayMnemonic[i] === "") {
          throw new Error(t("errors.mnemonic_must_have_12_words"));
        }
      }
      setMnemonicSubmitted(true);
    } catch (err) {
      const error = err as Error;
      openSnackbar(t("errors.something_went_wrong"), error.message, "error");
    }
  }

  useEffect(() => {
    if (pin.length === 5) {
      handleLoadWalletFromMnemonic();
    }
  }, [pin]);

  return (
    <>
      {mnemonicSubmitted ? (
        <>
          <Pin
            pin={pin}
            setPin={setPin}
            isRegistration={true}
            resetPin={false}
          />
        </>
      ) : (
        <>
          <ScrollView
            style={{ backgroundColor: "#151721" }}
            contentContainerStyle={{
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 16,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: 600,
                gap: 16,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 16,
                  gap: 16,
                  justifyContent: "center",
                }}
              >
                {arrayMnemonic.map((word, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                        flexBasis: 150,
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: 16,
                        backgroundColor: "#232839",
                        padding: 16,
                      }}
                      key={"mnemonic-input-" + index}
                    >
                      <Text
                        style={{
                          color: "#fff",
                          fontSize: 16,
                          marginRight: 8,
                          minWidth: 24,
                        }}
                      >
                        {index + 1}.
                      </Text>
                      <TextInput
                        value={word}
                        style={{
                          width: "100%",
                          padding: 8,

                          color: "#fff",
                        }}
                        onChangeText={(text) => handleUpdate(text, index)}
                      />
                    </View>
                  );
                })}
              </View>

              <Pressable style={styles.button_style} onPress={handleSumbit}>
                <Text style={styles.button_text_style}>
                  {t("common.submit")}
                </Text>
              </Pressable>
            </View>
          </ScrollView>
        </>
      )}
    </>
  );
}
const styles = StyleSheet.create({
  button_style: {
    marginTop: 32,
    backgroundColor: "#DB7BC6",
    minWidth: "50%",
    borderRadius: 16,
    padding: 24,
    paddingVertical: 16,
    zIndex: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  button_text_style: {
    fontSize: 20,
    color: "white",
    fontWeight: "bold",
    fontFamily: "Gilroy-Bold",
  },
  input_container: {
    backgroundColor: "transparent",
    width: "100%",
    padding: 8,
    paddingRight: 16,
    marginBottom: 32,
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderColor: "#aaa",
    borderRadius: 8,
    borderWidth: 3,
  },
  mnemonic_input: {
    color: "white",
    backgroundColor: "transparent",
    flex: 1,
    minHeight: Platform.OS === "ios" ? 50 : undefined,
  },
});
