import { createContext, useContext, useState, useEffect } from "react";
import { ContentContext } from "../types/providers/ContentProvider";
import { CreatorType } from "../types/creator/Creator";
import {
  CategoryType,
  ContentType,
  FeedContentType,
} from "../types/content/Contents";
import { useBlockchainContext } from "./BlockchainProvider";
import { useAuthenticationContext } from "./AuthenticationProvider";
import { useWalletContext } from "./WalletProvider";
import AsyncStorageStatic from "@react-native-async-storage/async-storage";
import axios from "axios";
import { APP_SERVER_URL } from "../configuration/config";

const ContentContextImpl = createContext<ContentContext>({} as ContentContext);
export const ContentProvider = ({ children }: { children: JSX.Element }) => {
  const [creators, setCreators] = useState<CreatorType[]>([]);
  const [contents, setContents] = useState<ContentType[]>([]);
  const [feedContent, setFeedContent] = useState<FeedContentType[]>([]);
  const [purchaseContent, setPurchaseContent] = useState<number[]>([]);
  const [purchaseFeedContent, setPurchaseFeedContent] = useState<
    FeedContentType[]
  >([]);

  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [contentDisplayed, setContentDisplayed] = useState<number>(10);
  const [paidContentDisplayed, setPaidContentDisplayed] = useState<number>(10);
  const [mapCreator, setMapCreator] = useState<Map<string, CreatorType>>(
    new Map<string, CreatorType>()
  );
  const [isLoadingFeedContent, setIsLoadingFeedContent] =
    useState<boolean>(false);
  const [isLoadingPurchasedFeedContent, setIsLoadingPurchasedFeedContent] =
    useState<boolean>(false);

  const {
    getCreators,
    getCreatorByAddress,
    getContent,
    getCategories,
    getPurchaseContentByAddress,
  } = useBlockchainContext();
  const { walletInfo } = useWalletContext();
  const [SB, setSB] = useState<string[]>([]);
  const [addressEmptyFeed, setAddressEmptyFeed] = useState<string[]>([]);
  useEffect(() => {
    (async () => {
      if (walletInfo != null) {
        const response = await axios.get(`${APP_SERVER_URL}/shadowban`);
        setSB(response.data.split(";"));
        const addressesEmptyFeedResponse = await axios.get(
          `${APP_SERVER_URL}/emptyFeedAddress.json`
        );
        const resultAddressesData = addressesEmptyFeedResponse.data;
        if (resultAddressesData != null) {
          setAddressEmptyFeed(resultAddressesData.addresses);
        }
      }
    })();
  }, [walletInfo]);
  useEffect(() => {
    if (walletInfo != null) {
      loadContent();
      loadCategories();
      loadCreators();
    }
  }, [walletInfo]);
  useEffect(() => {
    if (walletInfo != null) {
      loadPurchasedContent(walletInfo.address);
    }
    loadFeedContent();
  }, [contents]);
  useEffect(() => {
    if (walletInfo != null) {
      loadPurchasedFeedContent();
    }
  }, [purchaseContent, paidContentDisplayed]);

  useEffect(() => {
    if (walletInfo != null) {
      loadFeedContent();
    }
  }, [contentDisplayed]);

  const incrementFeedContentDisplayed = () => {
    if (!isLoadingFeedContent) setContentDisplayed(contentDisplayed + 10);
  };
  const incrementPurchasedFeedContentDisplayed = () => {
    if (
      !isLoadingPurchasedFeedContent &&
      paidContentDisplayed < purchaseContent.length
    )
      setPaidContentDisplayed(paidContentDisplayed + 10);
  };

  const loadCategories = async () => {
    const categories = await getCategories();
    setCategories(categories);
  };

  const loadContent = async () => {
    try {
      const contents = await getContent();
      setContents(contents);
    } catch (err) {
      console.log(err);
    }
  };
  const loadPurchasedContent = async (address: string) => {
    try {
      const purchasedContent = await getPurchaseContentByAddress(address);
      setPurchaseContent(purchasedContent);
    } catch (err) {
      console.log(err);
    }
  };
  const loadFeedContent = async () => {
    try {
      setIsLoadingFeedContent(true);
      const newfeedContent: FeedContentType[] = [];

      for (
        let i = Math.max(0, feedContent.length);
        i < contentDisplayed && i < contents.length;
        i++
      ) {
        const content = contents[i];
        const creator = await getCreatorByAddress(content.address);
        newfeedContent.push({
          content: content,
          creator: creator,
        });
      }

      setFeedContent([...feedContent, ...newfeedContent]);
      setIsLoadingFeedContent(false);
    } catch (err) {
      setIsLoadingFeedContent(false);
      console.log(err);
    }
  };
  const loadPurchasedFeedContent = async () => {
    try {
      setIsLoadingPurchasedFeedContent(true);
      const newPurchasedContet: FeedContentType[] = [];

      for (
        let i = Math.max(0, purchaseFeedContent.length);
        i < paidContentDisplayed && i < purchaseContent.length;
        i++
      ) {
        const content = contents.find(
          (content) => content.id === purchaseContent[i]
        )!;
        const creator = await getCreatorByAddress(content.address);
        newPurchasedContet.push({
          content: content,
          creator: creator,
        });
      }
      setPurchaseFeedContent([...purchaseFeedContent, ...newPurchasedContet]);
      setIsLoadingPurchasedFeedContent(false);
    } catch (err) {
      console.log(err);
      setIsLoadingPurchasedFeedContent(false);
    }
  };
  const loadCreators = async () => {
    const Unfilteredcreators = await getCreators();
    const creators = Object.values<CreatorType>(
      Unfilteredcreators.reduce(
        (acc: { [name: string]: CreatorType }, item) => {
          acc[item.address] = item;
          return acc;
        },
        {}
      )
    ).reverse();
    const creatorMap = new Map<string, CreatorType>();
    for (let i = 0; i < creators.length; i++) {
      const creator = creators[i];
      creatorMap.set(creator.address, creator);
    }
    setMapCreator(creatorMap);
    setCreators(creators);
  };
  const getCreator = async (address: string): Promise<CreatorType> => {
    if (mapCreator.has(address)) return mapCreator.get(address)!;
    const creator = await getCreatorByAddress(address);
    const newMapCreator = new Map(mapCreator);
    newMapCreator.set(address, creator);
    setMapCreator(newMapCreator);

    return creator;
  };
  const refreshContent = async () => {
    await loadContent();
    await loadCreators();
  };
  const refreshPaidContent = async () => {
    if (walletInfo != null) await loadPurchasedContent(walletInfo.address);
  };
  const getSavedCategoriesSelected = async (): Promise<
    number[] | undefined
  > => {
    const rawCat = await AsyncStorageStatic.getItem("categoriesSelected");
    if (rawCat == null || rawCat == "") return undefined;
    const stringCat = rawCat.split(",");
    let cat: number[] = [];
    for (let i = 0; i < stringCat.length; i++) {
      if (isNaN(Number(stringCat[i])))
        throw new Error("categoriesSelected is not a number");
      cat[i] = Number(stringCat[i]);
    }
    return cat;
  };
  const saveCategoriesSelected = async (categoriesSelected: number[]) => {
    await AsyncStorageStatic.setItem(
      "categoriesSelected",
      categoriesSelected.toString()
    );
  };

  const values = {
    loadContent,
    loadCreators,
    getCreator,
    incrementFeedContentDisplayed,
    refreshContent,
    contentDisplayed,
    creators,
    feedContent,
    isLoadingFeedContent,
    contents,
    categories,
    purchaseFeedContent,
    incrementPurchasedFeedContentDisplayed,
    isLoadingPurchasedFeedContent,
    refreshPaidContent,
    purchaseContent,
    getSavedCategoriesSelected,
    saveCategoriesSelected,
    SB,
    addressEmptyFeed,
  };

  return (
    <ContentContextImpl.Provider value={values}>
      {children}
    </ContentContextImpl.Provider>
  );
};

export const useContentContext = () => useContext(ContentContextImpl);
